@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-BoldItalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Black-webfont.woff") format("woff");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-BlackItalic-webfont.woff") format("woff");
  font-weight: bolder;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/RobotoCondensed-Light-webfont.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/RobotoCondensed-LightItalic-webfont.woff") format("woff");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/RobotoCondensed-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/RobotoCondensed-Italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/RobotoCondensed-BoldItalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/RobotoCondensed-Bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Light-webfont.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-LightItalic-webfont.woff") format("woff");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-MediumItalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-Thin-webfont.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Fonts/Roboto/Roboto-ThinItalic-webfont.woff") format("woff");
  font-weight: lighter;
  font-style: italic;
}
/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Regular"), url("./assets/Fonts/Poppins/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Italic"), url("./assets/Fonts/Poppins/Poppins-Italic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Thin"), url("./assets/Fonts/Poppins/Poppins-Thin.woff") format("woff");
}
@font-face {
  font-family: "Poppins Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Thin Italic"), url("./assets/Fonts/Poppins/Poppins-ThinItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight"), url("./assets/Fonts/Poppins/Poppins-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight Italic"), url("./assets/Fonts/Poppins/Poppins-ExtraLightItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Light";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light"), url("./assets/Fonts/Poppins/Poppins-Light.woff") format("woff");
}
@font-face {
  font-family: "Poppins Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light Italic"), url("./assets/Fonts/Poppins/Poppins-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Medium"), url("./assets/Fonts/Poppins/Poppins-Medium.woff") format("woff");
}
@font-face {
  font-family: "Poppins Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Medium Italic"), url("./assets/Fonts/Poppins/Poppins-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins SemiBold"), url("./assets/Fonts/Poppins/Poppins-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins SemiBold Italic"), url("./assets/Fonts/Poppins/Poppins-SemiBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold"), url("./assets/Fonts/Poppins/Poppins-Bold.woff") format("woff");
}
@font-face {
  font-family: "Poppins Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold Italic"), url("./assets/Fonts/Poppins/Poppins-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraBold"), url("./assets/Fonts/Poppins/Poppins-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraBold Italic"), url("./assets/Fonts/Poppins/Poppins-ExtraBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Black";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Black"), url("./assets/Fonts/Poppins/Poppins-Black.woff") format("woff");
}
@font-face {
  font-family: "Poppins Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Black Italic"), url("./assets/Fonts/Poppins/Poppins-BlackItalic.woff") format("woff");
}
.indicator_role_developer {
  background-image: url(assets/img/normal_indicator_medical_product_developer.png);
}

.indicator_role_developer_select {
  background-image: url(assets/img/icon_medical_product_developer_selected.png) !important;
}

.indicator_role_medical_expert {
  background-image: url(assets/img/normal_indicator_medical_expert.png);
}

.indicator_role_medical_expert_select {
  background-image: url(assets/img/icon_medical_expert_selected.png) !important;
}

.button_sign_up, .button_login {
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  height: 40px;
  line-height: normal;
  text-align: center;
  width: 120px;
}

.button_modal_close, .button_modal_back {
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 !important;
  padding-bottom: 3px;
}
.button_modal_close:hover, .button_modal_back:hover {
  border-bottom: 2px solid hsl(50, 82%, 33%);
  -webkit-filter: invert(0.5) sepia(1) saturate(2.4) hue-rotate(10.8deg) brightness(0.85);
  filter: invert(0.5) sepia(1) saturate(2.4) hue-rotate(10.8deg) brightness(0.85);
}

.button_resend_verification_email {
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  line-height: normal;
  text-align: center;
}

.button-intro-popup-next, .button-intro-popup-prev {
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 !important;
  padding-bottom: 3px;
  display: inherit;
  margin: 0 auto !important;
  padding: 0;
  width: 13px;
}

.button_sign_in_up {
  background: hsl(181, 39%, 48%) !important;
  border-radius: 5px;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  height: 40px;
  width: 300px;
  box-sizing: border-box;
  line-height: normal;
  text-align: center;
  text-align: center;
}
.button_annotation_commment_box_reply {
  background-color: hsl(0, 0%, 90%);
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 5px;
  padding: 3px;
  text-align: center;
  width: 100px;
}
.button_switch_role {
  background: inherit;
  background-color: hsl(181, 39%, 48%);
  border: none;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 60px;
  left: 0;
  position: relative;
  top: -57px;
  width: 400px;
}
.button_show_role_switcher {
  display: block;
}
.button_hide_role_switcher {
  display: none;
}
.button_sign_up {
  background-color: hsl(181, 39%, 48%) !important;
}
.button_sign_up:hover {
  background-color: hsl(180, 100%, 60%) !important;
}
.button_sign_up:active {
  background-color: hsl(181, 39%, 48%) !important;
}
.button_login {
  background-color: initial !important;
  border: 2px solid #fff !important;
}
.button_login:hover {
  background-color: hsl(50, 82%, 33%) !important;
  border-color: hsl(50, 82%, 33%) !important;
}
.button_resend_verification_email {
  background-color: hsl(181, 39%, 48%);
  color: set_color(flat-colors, white-color);
  font-family: "Poppins Regular", sans-serif;
  margin-top: 15px;
  padding: 2px;
  width: 320px;
}
.button_link_to_login {
  background-color: hsl(181, 39%, 48%);
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  height: 40px;
  line-height: normal;
  margin: 15px;
  padding: 2px;
  text-align: center;
  width: 180px;
}
.button_session_expire_counter {
  background-color: hsl(181, 39%, 48%) !important;
  border-radius: 5px;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  height: 40px;
  width: 300px !important;
}
.button_modal_close {
  float: right;
  height: 20px;
  width: 15px;
}
.button_modal_back {
  float: left;
  height: 28px;
  padding: 0;
  top: 0;
  width: 13px;
}
.button-intro-popup-prev {
  height: 24px;
}
.button-intro-popup-next {
  height: 28px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
button:disabled {
  background: #E9ECEF !important;
  color: rgb(107, 111, 121);
  cursor: not-allowed !important;
}

.normal_icon_user_name {
  background-image: url(assets/img/icon_user_name_default.svg);
}

.normal_icon_user_name_select {
  background-image: url(assets/img/icon_user_name_select.svg);
}

.normal_icon_user_name_confirm {
  background-image: url(assets/img/icon_user_name_confirm.svg);
}

.normal_icon_email {
  background-image: url(assets/img/icon_email_default.svg);
}

.normal_icon_email_select {
  background-image: url(assets/img/icon_email_select.svg);
}

.normal_icon_email_confirm {
  background-image: url(assets/img/icon_email_confirm.svg);
}

.normal_icon_password {
  background-image: url(assets/img/icon_password_default.svg);
}

.normal_icon_password_select {
  background-image: url(assets/img/icon_password_select.svg);
}

.normal_icon_password_confirm {
  background-image: url(assets/img/icon_password_confirm.svg);
}

.normal_icon_repeat_password {
  background-image: url(assets/img/icon_password_check_default.svg);
}

.normal_icon_repeat_password_select {
  background-image: url(assets/img/icon_password_check_select.svg);
}

.normal_icon_repeat_password_confirm {
  background-image: url(assets/img/icon_password_check_confirm.svg);
}

.normal_control_icon_user_account {
  background-image: url(assets/img/normal_control_icon_user_account.svg);
}

.icon_filter_category {
  background-image: url(assets/img/player_sidebar/icon_filter.svg);
}
.icon_filter_category:hover {
  background-image: url(assets/img/player_sidebar/icon_filter_select.svg);
}

.icon_filter_category_select {
  background-image: url(assets/img/player_sidebar/icon_filter_select.svg) !important;
}

.icon_filter_person {
  background-image: url(assets/img/player_sidebar/icon_person_filter.svg);
}
.icon_filter_person:hover {
  background-image: url(assets/img/player_sidebar/icon_person_filter_selected.svg);
}

.icon_filter_person_select {
  background-image: url(assets/img/player_sidebar/icon_person_filter_selected.svg);
}

.icon_filter_notify_active,
.icon_filter_notify_active_select {
  background-image: url(assets/img/player_sidebar/icon_notify_filter_active.svg);
}

.icon_filter_notify {
  background-image: url(assets/img/player_sidebar/icon_notify_filter.svg);
}
.icon_filter_notify:hover {
  background-image: url(assets/img/player_sidebar/icon_notify_filter_select.svg);
}

.icon_filter_notify_select {
  background-image: url(assets/img/player_sidebar/icon_notify_filter_select.svg);
}

.icon_sort_category {
  background-image: url(assets/img/player_sidebar/icon_sort.svg);
}

.icon_sort_category_select {
  background-image: url(assets/img/player_sidebar/icon_sort_select.svg);
  width: 15px;
  height: 12px;
}

.comment_icon_all_category_selected {
  background-image: url(assets/img/player_sidebar/icon_all_categories.svg);
}

.comment_icon_all_category {
  background-image: url(assets/img/player_sidebar/icon_all_categories.svg);
  width: 15px;
  height: 12px;
}

.icon_need {
  background-image: url(assets/img/player_sidebar/icon_need_default.svg);
}

.icon_pain {
  background-image: url(assets/img/player_sidebar/icon_problem_default.svg);
}

.icon_error {
  background-image: url(assets/img/player_sidebar/icon_error_default.svg);
}

.icon_comment {
  background-image: url(assets/img/player_sidebar/icon_comment_small_default.svg);
}

.icon_request {
  background-image: url(assets/img/player_sidebar/icon_questionmark.svg);
}

.icon_Idea {
  background-image: url(assets/img/player_sidebar/icon_idea_default.svg);
}

.icon_todo {
  background-image: url(assets/img/player_sidebar/icon_check_default.svg);
}

.selected_tag_need {
  background-image: url(assets/img/player_sidebar/icon_need_default.svg);
}

.selected_tag_problem {
  background-image: url(assets/img/player_sidebar/icon_problem_default.svg);
}

.selected_tag_close {
  background-image: url(assets/img/player_sidebar/icon_close.svg);
}

.comment_box_settings {
  background-image: url(assets/img/normal_control_icon_settings.svg);
}

.collaborate_icon {
  background-image: url(assets/img/player/normal_icon_collaborate_default.svg);
}

.comment_attachment_icon {
  background-image: url(assets/img/player/normal_icon_attach_default.svg);
}

.comment_connect_icon {
  background-image: url(assets/img/player/normal_icon_connect_default.svg);
}

.comment_video_icon {
  background-image: url(assets/img/player/normal_icon_video_default.svg);
}

.button_modal_close {
  background-image: url(assets/img/icon_delete.svg);
}

.button_modal_back {
  background-image: url(assets/img/normal_icon_back.svg);
}

.video-js .vjs-play-control .vjs-icon-placeholder:before {
  background-image: url(assets/img/player/icon_play_default.svg) !important;
}
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  background-image: url(assets/img/player/icon_pause_default.svg) !important;
}

.vjs-icon-fullscreen-enter:before {
  background-image: url(assets/img/player/icon_full_screen_default.svg);
}

.vjs-icon-next-enter:before {
  background-image: url(assets/img/player/icon_forward_default.svg);
}

.vjs-icon-prev-enter:before {
  background-image: url(assets/img/player/icon_forward_default.svg);
}

.close_comment_settings {
  background-image: url(assets/img/player_sidebar/icon_close.svg);
}

.close_comment_box {
  background-image: url(assets/img/player_sidebar/icon_close.svg);
}

.finish_comment_box {
  background-image: url(assets/img/player_sidebar/icon_check_default.svg);
}

.icon_play {
  background-image: url(assets/img/player_sidebar/icon_play.svg);
}

.icon_comment_audio_stop {
  background-image: url(assets/img/comment/icon_stopp_default.svg);
}

.icon_comment_audio {
  background-image: url(assets/img/comment/icon_audio_comment.svg);
}

.icon_comment_audio_play {
  background-image: url(assets/img/comment/icon_play.svg);
}

.icon_comment_audio_pause {
  background-image: url(assets/img/comment/icon_pause.svg);
}

.icon_comment_reply {
  background-image: url(assets/img/player_sidebar/icon_comment_reply.svg);
}

.icon_down {
  background-image: url(assets/img/player_sidebar/icon_arrow_default.svg);
}

.icon_up {
  background-image: url(assets/img/player_sidebar/icon_arrow_default.svg);
}

.inro-popup-next-block:hover > .button-intro-popup-next, .inro-popup-prev-block:hover > .button-intro-popup-prev {
  background-image: url(assets/img/intro_popup/intro-next-prev-hover-btn.svg) !important;
}

.swimlane-taskgroup-arrow-down-icon {
  background-image: url(assets/img/player_workflowbar/icon_arrow_down.svg);
  background-repeat: no-repeat;
}

.swimlane-taskoverflow-info-icon {
  background-image: url(assets/img/player_workflowbar/icon_info.svg);
  background-repeat: no-repeat;
}

.swimlane-time-arrow-icon {
  background-image: url(assets/img/player_workflowbar/icon_task_time_arrow.svg);
  background-repeat: no-repeat;
}

.swimlane-task-duration-icon {
  background-image: url(assets/img/player_workflowbar/icon_task_duration.svg);
  background-repeat: no-repeat;
}

.search_icon_box {
  background-image: url(assets/img/normal_icon_search.svg);
  background-repeat: no-repeat;
}

.search_icon_box_select {
  background-image: url(assets/img/normal_icon_search_select.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-dropdown-icon {
  background-image: url(assets/img/player_workflowbar/icon_accordion_arrow_down.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-dropdown-icon-selected {
  background-image: url(assets/img/intro_popup/icon_accordion_arrow_down_selected.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-general-info {
  background-image: url(assets/img/intro_popup/icon_general_info.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-general-info-selected {
  background-image: url(assets/img/intro_popup/icon_general_info_selected.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-workflow-overview {
  background-image: url(assets/img/intro_popup/icon_workflow_info.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-workflow-overview-selected {
  background-image: url(assets/img/intro_popup/icon_workflow_info_selected.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-rooms-equipment {
  background-image: url(assets/img/intro_popup/icon_rooms_equipment.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-rooms-equipment-selected {
  background-image: url(assets/img/intro_popup/icon_rooms_equipment_selected.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-tools-pharma {
  background-image: url(assets/img/intro_popup/icon_tools_pharma.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-tools-pharma-selected {
  background-image: url(assets/img/intro_popup/icon_tools_pharma_selected.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-roles-responsibilites {
  background-image: url(assets/img/intro_popup/icon_roles_resp.svg);
  background-repeat: no-repeat;
}

.drop-down-tab-icon-roles-responsibilites-selected {
  background-image: url(assets/img/intro_popup/icon_roles_resp_selected.svg);
  background-repeat: no-repeat;
}

.workflow-library-item-list-icon {
  background-image: url(assets/img/workflow_lib/icon_workflow_list.svg);
  background-repeat: no-repeat;
}

.workflow-library-item-list-icon.active {
  background-image: url(assets/img/workflow_lib/icon_workflow_list_active.svg);
  background-repeat: no-repeat;
}

.workflow-library-item-list-setting-icon {
  background-image: url(assets/img/settings/icon_setting.svg);
  background-repeat: no-repeat;
}

.workflow-library-nav-item-block:hover .workflow-library-item-list-setting-icon {
  background-image: url(assets/img/settings/icon_setting_hover.svg);
  background-repeat: no-repeat;
}

.workflow-library-item-list-setting-icon.active {
  background-image: url(assets/img/settings/icon_setting_active.svg);
  background-repeat: no-repeat;
}

.workflow-library-logout-btn-icon {
  background-image: url(assets/img/workflow_lib/icon_logout.svg);
  background-repeat: no-repeat;
}

.workflow-library-getsupport-btn-icon {
  background-image: url(assets/img/workflow_lib/icon_supportbubble.png);
  background-repeat: no-repeat;
}

.drop-down-menu-icon-sort {
  background-image: url(assets/img/drop_down_icon_sort.svg);
  background-repeat: no-repeat;
}

.drop-down-menu-icon-arrow {
  background-image: url(assets/img/drop_down_icon_arrow.svg);
  background-repeat: no-repeat;
}

.workflow-library-header-section-title-icon-star {
  background-image: url(assets/img/workflow_lib/icon_favorite.svg);
  background-repeat: no-repeat;
}

.workflow-library-header-section-title-icon-workflow {
  background-image: url(assets/img/workflow_lib/icon_workflow_list.svg);
  background-repeat: no-repeat;
}

.drop-down-menu-icon-category {
  background-image: url(assets/img/workflow_lib/icon_workflow_category.svg);
  background-repeat: no-repeat;
}

.workflow-library-header-section-fav-icon {
  background-image: url(assets/img/workflow_lib/icon_favorite_fill.svg);
  background-repeat: no-repeat;
}

.workflow-library-header-section-bin-icon {
  background-image: url(assets/img/workflow_lib/icon_bin.svg);
  background-repeat: no-repeat;
}

.workflow-library-workflow-item-details-fav {
  background-image: url(assets/img/workflow_lib/icon_favorite_white.svg);
  background-repeat: no-repeat;
}

.workflow-library-workflow-item-details-fav-active {
  background-image: url(assets/img/workflow_lib/icon_favorite_active.svg);
  background-repeat: no-repeat;
}

.workflow-library-workflow-item-details-fav-hover {
  background-image: url(assets/img/workflow_lib/icon_favorite_hover.svg);
  background-repeat: no-repeat;
}

.workflow-library-workflow-item-play {
  background-image: url(assets/img/workflow_lib/icon_workflow_play.svg);
  background-repeat: no-repeat;
}

.workflow-library-workflow-item-edit {
  background-image: url(assets/img/workflow_lib/icon_edit.svg);
  background-repeat: no-repeat;
}

.workflow-library-change-arrow {
  background-image: url(assets/img/workflow_lib/icon_arrow_down.svg);
  background-repeat: no-repeat;
}

.workflow-arrow-icon-side-infobar {
  background-image: url(assets/img/player_workflowbar/icon_arrow_workflow.svg);
  background-repeat: no-repeat;
}

.workflow-arrow-icon-side-infobar-selected {
  background-image: url(assets/img/player_workflowbar/icon_arrow_workflow_selected.svg);
  background-repeat: no-repeat;
}

.drop-down-menu-icon-eng {
  background-image: url(assets/img/settings/icon_eng.svg);
  background-repeat: no-repeat;
}

.drop-down-menu-icon-de {
  background-image: url(assets/img/settings/icon_de.svg);
  background-repeat: no-repeat;
}

.drop-down-menu-icon-jp {
  background-image: url(assets/img/settings/icon_jp.svg);
  background-repeat: no-repeat;
}

.workflow-library-logo-block::before {
  background-image: url(assets/img/player_workflowbar/close_indicator.svg);
  background-repeat: no-repeat;
}

.workflow-library-page-heading-icon {
  background-image: url(assets/img/player_workflowbar/icon_workflow_heading.svg);
  background-repeat: no-repeat;
}

.drop-down-icon {
  background-image: url(assets/img/comment/drop_down_icon.svg);
  background-repeat: no-repeat;
}

.icon-download-small {
  background-image: url(assets/img/comment/icon_download_small.svg);
  background-repeat: no-repeat;
}

.comment-edit {
  background-image: url(assets/img/comment/icon_edit.svg);
  background-repeat: no-repeat;
}

.comment-respond-to {
  background-image: url(assets/img/comment/icon_respond_to_comment.svg);
  background-repeat: no-repeat;
}

.comment-media-full-slider {
  background-image: url(assets/img/comment/icon_fullscreen.svg);
  background-repeat: no-repeat;
}

.comment-footer-delete-bin {
  background-image: url(assets/img/comment/icon_delete_comment.svg);
  background-repeat: no-repeat;
}

.comment-footer-delete-bin:hover {
  background-image: url(assets/img/comment/icon_delete_comment_hover.svg) !important;
  background-repeat: no-repeat;
}

.mini-slider-prev-icon,
.mini-slider-next-icon {
  background-image: url(assets/img/comment/slider_arrow.svg) !important;
  background-repeat: no-repeat;
}

.full-slider-prev-icon,
.full-slider-next-icon {
  background-image: url(assets/img/comment/slider_arrow_white.svg) !important;
  background-repeat: no-repeat;
}

.mini-slider-back-btn {
  background-image: url(assets/img/comment/btn_back.svg) !important;
  background-repeat: no-repeat;
}

.full-slider-close-icon {
  background-image: url(assets/img/comment/slider_close_icon.svg) !important;
  background-repeat: no-repeat;
}

.play-button-icon-big {
  background-image: url(assets/img/comment/play_button_icon.svg) !important;
  background-repeat: no-repeat;
}

.play-button-icon-small {
  background-image: url(assets/img/comment/play_button_small_icon.svg) !important;
  background-repeat: no-repeat;
}

.comment_audio_close {
  background-image: url(assets/img/comment/icon_close_audio.svg);
}

.comment_audio_edit {
  background-image: url(assets/img/comment/icon_comment_edit_audio.svg);
}

.comment_icon_notice {
  background-image: url(assets/img/comment/icon_notice.svg);
}

.comment_card_audio_icon {
  background-image: url(assets/img/comment/audio_icon.svg);
}

.comment_card_audio_icon_active {
  background-image: url(assets/img/comment/audio_icon_active.svg);
}

.comment_file_play_icon {
  background-image: url(assets/img/comment/play_btn_icon.svg);
}

.comment_icon_notice_selected {
  background-image: url(assets/img/comment/icon_notice_selected.svg);
  background-repeat: no-repeat;
}

.comment_icon_comment {
  background-image: url(assets/img/comment/icon_comment.svg);
}

.comment_icon_comment_selected {
  background-image: url(assets/img/comment/icon_comment_selected.svg);
  background-repeat: no-repeat;
}

.comment_icon_ask_ai {
  background-image: url(assets/img/comment/icon_ai_magic.svg);
}

.comment_icon_ask_ai_selected {
  background-image: url(assets/img/comment/icon_ai_magic_selected.svg);
  background-repeat: no-repeat;
}

.comment_icon_question {
  background-image: url(assets/img/comment/icon_exam.svg);
}

.comment_icon_question_selected {
  background-image: url(assets/img/comment/icon_exam_selected.svg);
  background-repeat: no-repeat;
}

.comment_drop_down_arrow {
  background-image: url(assets/img/comment/icon_drop_down_arrow.svg);
  background-repeat: no-repeat;
}

.comment_drop_down_arrow_selected {
  background-image: url(assets/img/comment/icon_drop_down_arrow_opened.svg);
  background-repeat: no-repeat;
}

.comment_setting_icon {
  background-image: url(assets/img/comment/icon_settings.svg);
  background-repeat: no-repeat;
}

.comment_setting_icon_active {
  background-image: url(assets/img/comment/icon_settings_active.svg);
  background-repeat: no-repeat;
}

.comment_upload_icon_small {
  background-image: url(assets/img/comment/icon_upload_file_small.svg);
  background-repeat: no-repeat;
}

.comment_upload_icon {
  background-image: url(assets/img/comment/icon_upload_file.svg);
  background-repeat: no-repeat;
}

.comment_upload_icon_active {
  background-image: url(assets/img/comment/icon_upload_file_active.svg);
  background-repeat: no-repeat;
}

.comment_close_icon {
  background-image: url(assets/img/comment/icon_close_comment.svg);
  background-repeat: no-repeat;
}

.comment_close_icon.error {
  background-image: url(assets/img/comment/icon_close_comment_white.svg);
  background-repeat: no-repeat;
}

.comment_check_icon {
  background-image: url(assets/img/comment/icon_check_comment.svg);
  background-repeat: no-repeat;
}

.comment_check_icon.error {
  background-image: url(assets/img/comment/icon_check_comment_white.svg);
  background-repeat: no-repeat;
}

.comment_picture_video_icon {
  background-image: url(assets/img/comment/icon_upload_picture_video.svg);
  background-repeat: no-repeat;
}

.comment_upload_check {
  background-image: url(assets/img/comment/icon_check.svg);
  background-repeat: no-repeat;
}

.delete_uploaded_file {
  background-image: url(assets/img/comment/delete_uploaded_file.svg);
  background-repeat: no-repeat;
}

.drop_down_arrow_reply {
  background-image: url(assets/img/comment/icon_drop_down_arrow_reply.svg);
  background-repeat: no-repeat;
}

.drop_down_arrow_reply_open {
  background-image: url(assets/img/comment/icon_drop_down_arrow_reply_open.svg);
  background-repeat: no-repeat;
}

h1 {
  font-size: var(--fs-800);
}

/*------------------------------------------------------------------------------*/
/*BASIC SETUP*/
/*------------------------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* THIS SHOULD NOT BE NONE BUT RATHER LIKE HOVER BUTTONS */
*:focus {
  outline: none;
}

html,
body {
  font-family: "Roboto";
  background-color: #ffffff;
  background-image: #ffffff;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  line-height: 1.3;
  -webkit-text-size-adjust: 125%;
  scroll-behavior: smooth;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

.container-reset-page, .container-register-success-page, .container-login-page, .container-sign-up-page {
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  height: 100vh;
}

.block-login, .block-signup-success, .block-reset, .block-signup-verify, .container-signup-block {
  margin: 0 auto;
  padding: 15px 25px;
  position: relative;
  max-width: 950px;
}

.container_recaptcha_resetr, .container_recaptcha_register, .container_recaptcha_login, .container_recaptcha_forgot {
  display: table;
  margin: 7px auto;
}

.container {
  background: hsl(0, 0%, 90%);
  border: 1px solid rgba(0, 0, 0, 0.274);
  flex-direction: column;
  height: auto;
  margin: 50px auto 0;
  position: relative;
  width: 500px;
  word-break: break-all;
}
.container-sign-up-page {
  padding-top: 8px;
}
.container-login-page {
  padding-top: 70px;
}
.container-signup-block {
  max-width: 950px !important;
}
.container_annotation {
  position: relative;
}
.container_process {
  order: 2;
}

.video-js {
  max-height: 100vh;
  height: auto;
  aspect-ratio: 2/3;
}

.block-login {
  max-width: 700px !important;
}

.vjs-subs-caps-button, .video-js .vjs-volume-panel, .video-js .vjs-volume-menu-button, .video-js .vjs-volume-bar, .video-js .vjs-time-divider, .video-js .vjs-remaining-time, .video-js .vjs-mute-control, .video-js .vjs-duration, .video-js .vjs-current-time {
  display: block;
}

.vjs-icon-prev-enter:before, .vjs-icon-next-enter:before, .vjs-icon-fullscreen-enter:before, .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before, .video-js .vjs-play-control .vjs-icon-placeholder:before {
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  content: "" !important;
  cursor: pointer;
  display: inline-block;
  height: 21px;
  margin-top: 20px;
  width: 18px;
}

.video-js .vjs-logo .vjs-logo-content {
  z-index: 20;
}
.video-js .vjs-mute-control .vjs-icon-placeholder:before {
  filter: invert(1);
}
.video-js .vjs-volume-control.vjs-control.vjs-volume-horizontal {
  filter: invert(1);
}
.video-js .vjs-volume-panel {
  position: absolute !important;
  right: 5px;
  top: 10px;
}
.video-js .vjs-progress-control {
  display: none !important;
  align-items: center;
  display: flex;
  flex: auto;
  height: 40% !important;
  left: 10px;
  top: 14px;
  width: 100% !important;
  width: 98% !important;
}
.video-js .vjs-progress-control .vjs-progress-holder {
  height: 4px;
  background: hsl(0, 0%, 90%);
}
.video-js .vjs-control-bar {
  background-color: #fff !important;
  display: block !important;
  height: 50px !important;
  visibility: visible !important;
  width: auto !important;
  z-index: 6 !important;
  bottom: -50px !important;
}
.video-js .vjs-control {
  height: auto !important;
}
.video-js .vjs-play-control {
  top: -14px;
}
.video-js .vjs-play-progress:before {
  border: 4px solid hsl(0, 0%, 50%);
  border-radius: 50%;
  color: transparent;
  font-size: 8px !important;
  right: -13px !important;
  top: -4px !important;
  z-index: 0 !important;
}

.vjs-poster {
  background-size: cover !important;
}

.vjs-has-started .vjs-control-bar, .vjs-audio-only-mode .vjs-control-bar {
  align-items: center;
  justify-content: center;
}

button.vjs-play-control.vjs-control.vjs-button.vjs-paused {
  top: -15px;
}

.vjs-icon-next-enter:before {
  height: 18px;
  width: 25px;
}

.player-btn-next {
  left: 72px;
  top: 10px;
  z-index: 1;
}

.vjs-icon-prev-enter:before {
  height: 18px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  width: 25px;
}

.player-btn-prev {
  left: 20px;
  top: 8px;
  z-index: 1;
}

.vjs-play-progress.vjs-slider-bar {
  background-color: hsl(0, 0%, 50%) !important;
  height: 8px !important;
  top: -1.7px !important;
}

.vjs-progress-holder.vjs-slider.vjs-slider-horizontal:before {
  border: 4px solid hsl(0, 0%, 40%);
  border-radius: 50%;
  color: transparent;
  content: "\f111";
  font-size: 8px !important;
  height: 16px;
  left: -12px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -6px !important;
  width: 16px;
  z-index: 2;
}

.player-btn-fullscreen {
  height: 30px !important;
  position: absolute !important;
  right: 5px !important;
  top: 10px !important;
  width: 30px !important;
  z-index: 10000 !important;
}

.player-btn-fullscreen-invert {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.vjs-mouse-display {
  z-index: 2 !important;
}

.vjs-mouse-display-hover {
  z-index: 0 !important;
}

.vjs-logo-content {
  cursor: pointer;
  padding: 10px 20px 20px 10px !important;
  z-index: 20;
}

.vjs-logo-content > a {
  pointer-events: none;
}

.vjs-logo-content img {
  width: 62px;
  height: 58px;
}

.workflowBar_Spinner {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.task-container {
  flex-direction: column;
  overflow: auto;
}

.person.label, .person-task-bar, .person-group-label, .person-group-init {
  align-items: center;
  display: flex;
  font-family: "Poppins SemiBold", "Poppins Regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.person-group-container {
  display: flex;
  width: 100%;
}
.person-group-task-container {
  background-color: #616161;
  flex: 90%;
  flex-direction: column;
}
.person-group-label {
  height: 100%;
  width: 50%;
}
.person-label-container {
  flex-direction: column;
  height: 100%;
  width: 80%;
}
.person-label {
  align-items: center;
  background-color: #eee;
  color: #000;
  display: flex;
  height: 35px;
  margin: 5px;
  padding-left: 20px;
  width: 100%;
}
.person-group-init {
  background-color: #aaa;
  border-left: 1px #000;
  color: #fff;
  display: flex;
  flex: 10%;
  min-width: 45px;
  width: 90%;
}
.person-task-bar {
  background-color: #616161;
  color: #000;
  display: flex;
  flex: 90%;
  height: 45px;
}
.task-box .task-box-selected, .task-box-hover, .task-box {
  align-self: center;
  border-bottom: 3px solid hsl(180, 100%, 60%);
  font-family: "Poppins SemiBold", "Poppins Regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  height: 35px;
  justify-content: center;
  margin: 5px 2px;
  overflow: hidden;
  padding: 4px 8px;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.task-box {
  background-color: hsl(0, 0%, 90%);
}
.task-box-hover {
  background-color: hsl(0, 0%, 70%);
  cursor: pointer;
}
.task-box .task-box-selected {
  background-color: #fff;
}

.container_process {
  position: relative;
  margin: 0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 7;
  background: #4a4a4a;
}
.container_process::-webkit-scrollbar {
  width: 10px;
}
.container_process::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 0;
}
.container_process::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.process-bar {
  background-color: hsl(0, 0%, 40%);
  display: block;
  flex: 90%;
  width: auto;
}
.process-bar, .process-init {
  align-self: center;
  color: #fff;
  font-family: "Poppins SemiBold", "Poppins Regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  height: 90px;
  justify-content: center;
  margin: 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.process-init {
  background-color: hsl(0, 0%, 30%);
  flex: 10%;
  width: 200px;
}
.process-box {
  background-color: hsl(180, 35%, 25%);
}
.process-box, .process-box-hover {
  border-bottom: 2px solid hsl(180, 100%, 60%);
  color: #fff;
}
.process-box-hover {
  background-color: hsl(181, 39%, 48%);
  cursor: pointer;
  padding-top: 5px;
}
.process-box, .process-box-hover, .process-box-selected {
  align-self: center;
  font-family: "Poppins SemiBold", "Poppins Regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  height: inherit;
  justify-content: center;
  margin: 2px;
  overflow: hidden;
  padding: 4px 8px;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.process-box-selected {
  background-color: hsl(180, 100%, 60%);
  border-bottom: 3px solid hsl(180, 100%, 60%);
  color: #000;
}
.process-timeline {
  background-color: hsl(0, 0%, 50%);
  height: 6px;
  position: relative;
  top: 18px;
  width: 99%;
  z-index: 2;
}
.process-indicator-background {
  background-color: initial;
  height: 61px;
  position: absolute;
  top: 18px;
  width: 0;
}
.process-indicator-line {
  background-color: #fff;
  height: inherit;
  left: 4px;
  position: relative;
  width: 8px;
}
.process-indicator-line-hover {
  background-color: hsl(180, 100%, 60%);
  height: inherit;
  left: 4px;
  position: relative;
  width: 8px;
}
.process-indicator-text {
  align-self: center;
  color: hsl(180, 100%, 60%);
  display: inline;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  text-align: right;
  top: -1px;
  z-index: 3;
  background: #393d41;
  padding: 0 2px;
  line-height: 20px;
}
.process-time-label {
  color: hsl(0, 0%, 90%);
  font-family: "Poppins Regular", sans-serif;
  left: -6px;
  position: absolute;
  text-align: center;
  top: -21px;
}
.process-thumbnail {
  margin-top: 5px;
}
.process-column {
  z-index: 2;
}
.process-thumbnail-box {
  background: rgba(97, 97, 97, 0.4784313725);
  color: #fff;
  display: none;
  font-family: "Poppins Regular", sans-serif;
  font-size: 13px;
  font-weight: 100;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: -275px;
  transition: 0.3s ease-in-out;
  width: 250px;
  z-index: -1;
}
.process-box:hover .process-box-thumbnail-box {
  display: block !important;
}
.process-column div:last-child span {
  left: auto !important;
  right: 0;
}
.process-bar-hover {
  pointer-events: auto !important;
}

.hiddenBar {
  height: 250px;
  position: absolute;
  top: 25px;
  width: 100%;
  z-index: 3;
}

.move_to_100, .move_to_90, .move_to_80, .move_to_70, .move_to_60, .move_to_50, .move_to_40, .move_to_30, .move_to_20, .move_to_10 {
  background: hsl(0, 0%, 90%);
  display: block;
  height: 6px;
  position: absolute;
  width: 5px;
}

.move_to_10 {
  left: 10%;
}

.move_to_20 {
  left: 20%;
}

.move_to_30 {
  left: 30%;
}

.move_to_40 {
  left: 40%;
}

.move_to_50 {
  left: 50%;
}

.move_to_60 {
  left: 60%;
}

.move_to_70 {
  left: 70%;
}

.move_to_80 {
  left: 80%;
}

.move_to_90 {
  left: 90%;
}

.move_to_100 {
  left: 100%;
}
.move_to_100 > .process-time-label {
  left: -20px;
}

figure:hover + .process-thumbnail-box {
  display: block;
  opacity: 1;
  top: -195px;
  z-index: 1;
}

div#process-indicator-background:before {
  background: #fff;
  border-left: 3.5px solid hsl(0, 0%, 20%);
  border-right: 3.5px solid hsl(0, 0%, 20%);
  content: " ";
  cursor: move;
  height: 61px;
  position: absolute;
  right: 0;
  width: 14px;
  z-index: 3;
}

div#process-indicator-background:before {
  pointer-events: auto;
}
div#process-indicator-background:before:hover {
  background-color: hsl(180, 100%, 60%) !important;
}

/* New swimlane code starts here */
#zoom-in-out-slide {
  background: #E9ECEF !important;
  height: 2px;
  user-select: none;
  pointer-events: none;
  top: 3px !important;
}
#zoom-in-out-slide .range-slider__range {
  background: hsl(0, 0%, 40%);
  transition: height 0.3s;
  height: 8px;
  user-select: none !important;
}
#zoom-in-out-slide .range-slider__range[data-active] {
  height: 9px;
}
#zoom-in-out-slide .range-slider__thumb {
  background: hsl(0, 0%, 90%);
  transition: transform 0.3s;
  border: 3px solid hsl(0, 0%, 40%);
  height: 16px;
  width: 16px;
  pointer-events: auto;
}
#zoom-in-out-slide .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

.range-slider__thumb:hover {
  cursor: e-resize;
}

.range-slider__thumb:hover + #zoom-in-out-slide {
  pointer-events: all !important;
}

.range-slider input[type=range] {
  display: none;
}

.swimlane-process-label:after, .swimlane-process-label:before {
  content: "";
  width: 50%;
  background: hsl(180, 100%, 60%);
  position: absolute;
  height: 5px;
  top: 8px;
  display: none;
}

.swimlane-process:before, .swimlane-process:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
}

.swimlane-container {
  display: flex;
  background-color: hsl(0, 0%, 40%);
  min-height: 35vh;
  position: relative;
  z-index: 4;
}
.swimlane-container::-webkit-scrollbar {
  width: 2px;
}
.swimlane-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.swimlane-container::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}
.swimlane-label-box {
  width: 270px;
  background: #6b6f79;
  color: #fff;
  border-right: 2px solid #fff;
  height: auto;
}
.swimlane-zooming-bar-block {
  width: 100%;
  overflow: hidden;
  padding: 2px 0;
  background: #393d41;
  position: relative;
  cursor: move;
  display: flex;
}
.swimlane-middle-block {
  position: absolute;
  width: 100%;
  height: 99%;
}
.swimlane-segment-wrapper {
  margin-top: 2px;
  display: flex;
  width: 100%;
  height: fit-content;
  position: absolute;
}
.swimlane-tasksBlock {
  position: relative;
  top: 55px;
  height: 80px;
}
.swimlane-task-segment {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: #f2f2f2;
  height: 21px;
  width: 100%;
  margin-right: 2px;
  position: relative;
  border: 2px solid transparent;
  line-height: 15px;
  outline: 0.5px #4a4a4a solid;
  z-index: 1 !important;
}
.swimlane-task-segment:hover {
  border: 2px solid #19eef2 !important;
  background: #4baaac !important;
  transition: 200ms ease-in-out;
}
.swimlane-task-segment:hover .swimlane-taskgroup-arrow-down-icon {
  display: inline-block;
}
.swimlane-taskgroup-arrow-down-icon {
  display: inherit;
  position: absolute;
  right: 2px;
  top: 2px;
}
.swimlane-process {
  background: #adb5bd;
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  position: relative;
  width: 100%;
  margin-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.swimlane-process:after {
  border-right: 10px solid #fff;
  right: 0;
  top: 0;
}
.swimlane-process:before {
  border-left: 10px solid #fff;
}
.swimlane-process:hover {
  transition: 200ms ease-in-out;
  background: #4baaac;
  color: #fff;
}
.swimlane-process:hover:after {
  border-right: 10px solid hsl(180, 100%, 60%) !important;
}
.swimlane-process:hover:before {
  border-left: 10px solid hsl(180, 100%, 60%) !important;
}
.swimlane-process-clicked {
  background-color: rgba(25, 238, 242, 0.2) !important;
}
.swimlane-task-folded {
  background-color: #19eef2 !important;
}
.swimlane-task-folded > .swimlane-process-label > .swimlane-process-label-inside {
  display: inline-block !important;
}
.swimlane-task-folded.swimlane-task-segment > .swimlane-taskoverflow-info-icon-box {
  background-color: #1f6163;
}
.swimlane-task-folded.swimlane-task-segment > .swimlane-task-info-card {
  display: block;
}
.swimlane-task-folded ~ .swimlane-segment-wrapper > .swimlane-task-segment {
  height: 20px !important;
}
.swimlane-task-folded ~ .swimlane-segment-wrapper {
  display: flex !important;
}
.swimlane-task-folded ~ .swimlane-segment-wrapper > .swimlane-task-segment > .swimlane-process-label > .swimlane-process-label-inside {
  display: inline-block;
}
.swimlane-process-label {
  pointer-events: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-left: 2px;
}
.swimlane-process-label:before {
  left: 4px;
}
.swimlane-process-label:after {
  right: 4px;
}
.swimlane-process-label-inside {
  z-index: 2;
  position: relative;
  padding: 0 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  line-height: 17px;
  display: inline-block;
  height: inherit;
}
.swimlane-time-line {
  width: 97%;
  margin-left: 18px;
  position: relative;
  height: 8px;
  cursor: pointer;
}
.swimlane-scrollable {
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  top: 30px;
  display: flex;
  flex-direction: column;
  height: 91%;
  margin-left: 0;
}
.swimlane-header {
  display: flex;
  background-color: #f2f2f2;
  height: 5px;
  padding-top: 5px;
  padding-left: 10px;
  z-index: 4;
  position: relative;
  gap: 5px;
}
.swimlane-search {
  background-color: #fff;
  border: none;
  border-radius: 2px;
  height: 30px;
  font-family: "Poppins Regular", sans-serif;
  font-weight: 500;
  size: 16px;
  line-height: 24px;
}
.swimlane-segment-indicator::before {
  background: #fff;
  outline: 2px solid hsl(0, 0%, 29.02%);
  content: " ";
  cursor: move;
  height: 100%;
  position: absolute;
  width: 4px;
  z-index: 5;
  pointer-events: auto;
  right: 0;
  top: 2;
}
.swimlane-segment-indicator:hover::before {
  background: #19eef2;
}
.swimlane-segment-indicator {
  background-color: initial;
  height: 96%;
  position: absolute;
  top: 20px;
  width: 0;
}
.swimlane-process-divider {
  width: 5px;
}
.swimlane-selected-phase {
  background: rgb(51, 94, 94);
  color: #19eef2;
}
.swimlane-selected-phase:after {
  border-right: 10px solid hsl(180, 100%, 60%) !important;
}
.swimlane-selected-phase:before {
  border-left: 10px solid hsl(180, 100%, 60%) !important;
}
.swimlane-selected-phase:hover .swimlane-process-label:after {
  display: none !important;
}
.swimlane-selected-phase:hover .swimlane-process-label:before {
  display: none !important;
}
.swimlane-selected-phase:hover .swimlane-process-label-inside {
  background-color: transparent !important;
}
.swimlane-selected-phase .swimlane-process-label:after {
  display: block !important;
}
.swimlane-selected-phase .swimlane-process-label:before {
  display: block !important;
}
.swimlane-selected-phase .swimlane-process-label-inside {
  background-color: rgb(51, 94, 94) !important;
}
.swimlane-sub-segment-wrapper {
  margin-left: 0;
  top: 22px;
}
.swimlane-sub-segment-wrapper .swimlane-task-segment {
  height: 4px;
}
.swimlane-taskgroup {
  height: 30px;
  margin: 0;
  padding: 0;
  z-index: 0;
}
.swimlane-taskgroup:hover {
  z-index: 15 !important;
}
.swimlane-taskgroup:hover .swimlane-maintask > .swimlane-task-segment {
  height: 20px !important;
}
.swimlane-taskgroup:hover .swimlane-segment-taskgroup {
  border: 2px solid #19eef2 !important;
  background: #4baaac !important;
  transition: 200ms ease-in-out;
}
.swimlane-taskgroup:hover .swimlane-maintask > .swimlane-task-segment > .swimlane-process-label > .swimlane-process-label-inside {
  display: inline-block;
}
.swimlane-taskgroup:hover .swimlane-task-segment > .swimlane-task-info-card {
  display: block;
}
.swimlane-hover-task-segment {
  border: 2px solid #19eef2;
  background: #4baaac;
}
.swimlane-maintask:hover {
  height: 50px;
}
.swimlane-maintask > .swimlane-task-segment > .swimlane-process-label > .swimlane-process-label-inside {
  display: none;
}
.swimlane-maintask:hover .swimlane-subtask > .swimlane-task-segment > .swimlane-process-label > .swimlane-process-label-inside {
  display: inline-block;
}
.swimlane-maintask:hover .swimlane-subtask {
  display: flex;
}
.swimlane-maintask:hover .swimlane-subtask > .swimlane-task-segment {
  height: 20px;
}
.swimlane-maintask:hover .swimlane-task-segment > .swimlane-task-info-card {
  display: block;
}
.swimlane-subtask {
  display: none;
}
.swimlane-subtask > .swimlane-task-segment > .swimlane-process-label > .swimlane-process-label-inside {
  display: none;
}
.swimlane-subtask:hover ~ .swimlane-task-segment {
  border: 2px solid #19eef2;
  background: #4baaac;
  transition: 200ms ease-in-out;
}
.swimlane-taskoverflow-info-icon-box:hover {
  background-color: #1f6163;
  cursor: help;
}
.swimlane-taskoverflow-info-icon-box-clicked {
  background-color: #1f6163 !important;
}
.swimlane-taskgroup-arrow-down-icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  display: none;
  pointer-events: none;
}
.swimlane-process-label-tooltip {
  font-family: "Poppins Regular" !important;
  color: #000 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  background-color: #4baaac !important;
  stroke-width: 2px !important;
  border: 2px solid #19eef2 !important;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  box-shadow: none !important;
  padding: 0 5px !important;
  height: 25px !important;
  line-height: 20px !important;
  z-index: 10;
}
.swimlane-process-label-tooltip:hover .swimlane-process-label-tooltip-segment-wrapper {
  z-index: 15;
  opacity: 1;
}
.swimlane-taskoverflow-info-icon-box {
  background: transparent;
  width: 19px;
  height: 17px;
  display: block;
  position: absolute;
  display: none;
}
.swimlane-taskoverflow-info-icon {
  height: 14px;
  width: 2px;
  position: absolute;
  left: 8px;
  top: 2px;
}
.swimlane-info-card-block {
  position: relative;
  width: auto;
  height: 20px;
  z-index: 12;
}
.swimlane-info-card-block-arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #ffffff;
  bottom: -8px;
  right: 28px;
  transform: rotate(45deg);
  z-index: 2;
}
.swimlane-task-info-card {
  position: absolute;
  font-family: "Poppins Regular";
  bottom: 35px;
  z-index: 5;
  flex-direction: column;
  width: 375px;
  right: 0px;
  border-radius: 5px;
  -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
  display: flex;
  gap: 5px;
}
.swimlane-task-info-card-left {
  right: auto;
  left: 5px !important;
}
.swimlane-task-info-card-left:after {
  right: auto;
  left: 25px !important;
}
.swimlane-info-card-heading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #212121;
  font-family: "Poppins Regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  gap: 5px;
}
.swimlane-info-card-task-label {
  width: 45%;
}
.swimlane-task-duration-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
}
.swimlane-time-arrow-icon {
  width: 20px;
  height: 11px;
  display: inline-block;
  margin: 0 4px;
}
.swimlane-info-card-tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 5px;
  column-gap: 6px;
  padding-top: 10px;
}
.swimlane-info-card-tag {
  background: #8bd6ae;
  height: 24px;
  border-radius: 5px;
  padding: 1px 8px;
}
.swimlane-task-duration {
  font-weight: 500;
  width: 55%;
  text-align: right;
}
.swimlane-info-card-task-description {
  display: inline-block;
  margin-top: 10px;
  padding-right: 4px;
  max-height: 435px;
}
.swimlane-tool-box-item {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0);
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  gap: 6px;
}
.swimlane-info-card-tool-box-item {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0);
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: baseline;
  justify-content: flex-start;
  cursor: pointer;
  gap: 6px;
  margin: 4px;
}
.swimlane-info-card-tool-box-item:hover {
  border-radius: 2px;
  background: #f2f2f2;
}
.swimlane-info-card-tool-box-item-selected {
  border-radius: 3px;
  outline: 2px solid #99830f;
}
.swimlane-info-card-tool-box {
  position: relative;
  margin-top: 8px;
  z-index: 2;
}
.swimlane-info-card-tool-box-item-label {
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-right: 1px solid #d9d9d9;
  padding-right: 10px;
  width: 215px;
  overflow: hidden;
  text-wrap: nowrap;
}
.swimlane-info-card-tool-box-item-category {
  height: 18px;
  flex-direction: column;
  justify-content: center;
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.swimlane-info-card-tool-box-details {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  right: 0;
  width: 375px;
  font-family: "Poppins Regular";
}
.swimlane-info-card-tool-box-details-title {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px 5px 0px 0px;
  background: #99830f;
}
.swimlane-info-card-body {
  display: flex;
  padding: 0 15px 10px 15px;
  flex-direction: column;
  align-items: flex-start;
}
.swimlane-info-card-content-box {
  background: #fff;
  border-left: 12px solid #f2f2f2;
  padding: 5px 10px 5px 5px;
  border-radius: 5px;
  max-height: 515px;
  overflow-y: auto;
}
.swimlane-info-card-content-box::-webkit-scrollbar {
  width: 4px;
}
.swimlane-info-card-content-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.swimlane-info-card-content-box::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}
.swimlane-quick-access-menu-container {
  width: 20px;
  flex-shrink: 0;
  border-left: 2px solid #fff;
}
.swimlane-time-line-input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  cursor: pointer;
  width: 100%;
  position: absolute;
  z-index: 3;
  top: -4px;
  left: 0;
}
.swimlane-time-line-input::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: hsl(180, 100%, 60%) !important;
  height: 15px !important;
  width: 15px !important;
  cursor: move !important;
}
.swimlane-time-line-input::-moz-range-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: hsl(180, 100%, 60%) !important;
  height: 15px !important;
  width: 15px !important;
  cursor: move !important;
}
.swimlane-time-line-input:hover::-webkit-slider-thumb {
  background-color: #4BAAAC !important;
  border: 2px solid hsl(180, 100%, 60%);
}
.swimlane-time-line-input:hover::-moz-range-thumb {
  background-color: #4BAAAC !important;
  border: 2px solid hsl(180, 100%, 60%);
}
.swimlane-time-line-input::-moz-range-progress {
  background-color: transparent !important;
  height: 8px !important;
}
.swimlane-segment-indicator-input {
  position: absolute;
  z-index: 10;
  height: 315px;
  top: 20px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  width: 100%;
  pointer-events: none;
}
.swimlane-segment-indicator-input:focus {
  outline: none !important;
}
.swimlane-segment-indicator-input::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: #fff !important;
  border: 2px solid hsl(0, 0%, 29.02%) !important;
  border-radius: 0 !important;
  cursor: move !important;
  height: 315px !important;
  width: 8px !important;
  pointer-events: auto !important;
}
.swimlane-segment-indicator-input::-webkit-slider-thumb:hover {
  background-color: #19eef2 !important;
}
.swimlane-segment-indicator-input::-moz-range-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: #fff !important;
  border: 2px solid hsl(0, 0%, 29.02%) !important;
  border-radius: 0 !important;
  height: 315px !important;
  cursor: move !important;
  pointer-events: auto !important;
  width: 4px !important;
}
.swimlane-segment-indicator-input::-moz-range-thumb:hover {
  background-color: #19eef2 !important;
}
.swimlane-segment-indicator-input::-moz-range-progress {
  background-color: transparent !important;
}

.timelane-indicator {
  height: 3px;
  width: 0;
  position: absolute;
  z-index: 3;
  top: 0;
}
.timelane-indicator::before {
  content: " ";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: hsl(180, 100%, 60%);
  position: absolute;
  z-index: 4;
  top: -4px;
  left: 0;
  right: auto;
  cursor: move;
  transition: left 0.5s, right 0.5s;
}
.timelane-indicator:hover::before {
  background-color: #4baaac !important;
  outline: 2px solid #19eef2;
}
.timelane-indicator:hover .timelane-indicator-label-box {
  display: block !important;
}
.timelane-indicator-label-tooltip {
  position: absolute;
  z-index: 6;
  background: #4baaac;
  stroke-width: 2px;
  border: 2px solid #19eef2;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 0 5px;
  height: 25px;
  color: #000;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  top: -40px;
}
.timelane-indicator-label-tooltip::before, .timelane-indicator-label-tooltip::after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}
.timelane-indicator-label-tooltip::before {
  border-top: 10px solid #19eef2;
  margin-top: 0;
}
.timelane-indicator-label-tooltip::after {
  border-top: 10px solid #4baaac;
  margin-top: -4px;
  z-index: 1;
}
.timelane-indicator-label-box {
  position: absolute;
  right: 27px;
  top: -43px;
  display: none;
}

.timeline-local {
  background-color: #ADB5BD;
  height: 6px;
  top: 5px;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 20px;
  cursor: pointer;
}

.timeline-local > div {
  pointer-events: none;
}

.timeline-local:after, .timeline-local:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  position: absolute;
}

.timeline-local:before {
  border-left: 5px solid #fff;
}
.timeline-local:after {
  border-right: 5px solid #fff;
  right: 0;
}

.timeline-end-time, .timeline-start-time {
  position: absolute;
  top: -21px;
  color: #fff;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  width: fit-content !important;
}

.timeline-start-time {
  left: 3px;
}

.timeline-end-time {
  right: 3px;
}

.workflow-side-infobar {
  flex-direction: column;
  text-align: right;
  display: flex;
  gap: 6px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  margin: 6px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.workflow-side-infobar:hover {
  background-color: #adb5bd;
}
.workflow-side-infobar-selected {
  background-color: #99830f;
}
.workflow-side-infobar {
  display: flex;
  flex-direction: row;
}
.workflow-details-side-infobar {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-right: 5px;
}
.workflow-name-side-infobar {
  color: #fff;
  text-align: right;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workflow-id-side-infobar {
  color: #fff;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workflow-arrow-side-infobar {
  display: flex;
  width: 45px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 5px 0px 0px 5px;
  background: transparent;
}
.workflow-arrow-side-infobar-hover {
  background: #fff;
}
.workflow-arrow-side-infobar-selected {
  background: #f2dd1d;
}
.workflow-name-tooltip-side-infobar {
  background: #4a4a4a;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 65px;
  z-index: 10;
  left: 0;
  border-radius: 5px;
  display: none;
}
.workflow-name-tooltip-side-infobar:after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  border-top: 7px solid #4a4a4a;
  margin-top: 0;
  right: 50%;
}
.workflow-side-persongroup-box {
  display: flex;
  width: 270px;
  min-height: 70px;
  padding-left: 0px;
  justify-content: space-between;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  font-family: "Poppins Regular";
  gap: 30px;
  margin-bottom: 5px;
  padding: 5px 0;
  overflow-x: auto;
}
.workflow-person-names-box {
  display: flex;
  flex-direction: column;
}
.workflow-person-name {
  border-bottom: 1px solid #fff;
  width: 130px;
  text-align: right;
  padding-right: 8px;
  min-height: 70px;
}
.workflow-persongroup-name {
  padding-left: 10px;
}
.workflow-arrow-icon-side-infobar {
  width: 24px;
  height: 24px;
}
.workflow-arrow-icon-side-infobar-selected {
  width: 24px;
  height: 24px;
}

.workflow-bar {
  background: #4a4a4a;
}

.closeBtn {
  filter: opacity(0.5);
  border: none;
  height: 12px;
}

.closeBtn:hover {
  filter: opacity(1) !important;
  border: none;
}

.workflow-name-side-infobar:hover ~ .workflow-name-tooltip-side-infobar {
  display: block;
}

.content_admin_main_block {
  display: flex;
  justify-content: center;
  padding: 25px;
  font-family: "Poppins Regular";
}

.content_admin_main_block > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.mask-side-bar {
  width: 1200px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6392156863);
  position: fixed;
  z-index: 15;
  top: 0;
}

.mask-side-bar-close-btn {
  position: absolute;
  right: 13px;
  top: 3px;
  font-family: "Poppins Regular";
  font-size: 20px;
}

.list-input-main-block {
  position: relative;
  align-items: baseline;
  min-width: 120px;
}

.list-input-sub-block {
  background: white;
  padding: 3px;
  border: solid black 1px;
  box-shadow: 1px 1px;
  text-align: center;
  min-width: 80px;
}

.content-admin-input-label {
  width: 100%;
  height: 20px;
  vertical-align: middle;
  text-align: center;
  padding: 2px;
}

.content-admin-select-phase {
  position: absolute;
  left: 35%;
  top: 0;
  border: dotted #4baaac 1px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  z-index: 5;
}

.content-admin-select-file {
  position: absolute;
  left: 35%;
  top: 0;
  border: dotted #4baaac 1px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  z-index: 3;
}

.content-admin-phase-item {
  cursor: pointer;
}

.content-admin-task-block, .content-admin-resource-block, .content-admin-phase-block {
  border: 1px dotted #4baaac;
  border-radius: 5px;
  padding: 10px;
}

.contet-admin-button-resource-block {
  width: 100px;
  height: auto;
  margin: 4px;
  border-radius: 5px;
}

.content-admin-box {
  align-items: baseline !important;
}

.content-admin-box > div {
  max-width: 50rem !important;
}

.content-admin-box > div > div {
  max-height: fit-content !important;
}

.control_button_switch_subphase {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #4baaac;
}

.content-upload-media-btn,
.content-admin-save-btn,
.content-admin-save-btn-success {
  width: 100px;
  background: #aceeef;
  margin-top: 15px;
  border-radius: 5px;
  font-family: "Poppins Regular";
  font-weight: 600;
  padding: 10px;
  margin-right: 10px;
}

.content-upload-media-btn {
  color: #FFF;
  background-color: #4baaac;
  width: auto;
}

.content-admin-save-btn {
  background: #4baaac !important;
  display: flex;
  gap: 10px;
  padding: 5px;
}

.content-admin-save-btn-success {
  background: #07f3c0 !important;
}

.ressource_upload_picture {
  display: flex;
  align-items: baseline;
}

.workflow-library-item-list-icon.active, .workflow-library-item-list-icon {
  display: flex;
  width: 20%;
  height: 32px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
}

.workflow-library-workflow-item-details-fav-hover, .workflow-library-workflow-item-details-fav-active, .workflow-library-workflow-item-details-fav {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 1px 0px 0.096px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.workflow-library-logo-block-closed::before, .workflow-library-logo-block-open::before {
  width: 15px;
  height: 80px;
  position: absolute;
  content: " ";
}

.workflow-library-main-container {
  display: flex;
  height: 100vh;
  position: relative;
  background: #f2f2f2;
}
.workflow-library-sidebar {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-right: 0px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  z-index: 11;
  transition: left 0.3s;
}
.workflow-library-sidebar.open {
  left: 0;
}
.workflow-library-sidebar.closed {
  left: -25%;
}
.workflow-library-logo-block {
  display: flex;
  padding: 5px 20px 20px 10px;
  height: 87px;
  cursor: pointer;
}
.workflow-library-logo-block-open::before {
  right: -1px;
}
.workflow-library-logo-block-closed::before {
  right: -16px;
  transform: rotate(180deg);
}
.workflow-library-logo {
  width: 62px;
  height: 58px;
  transition: transform 0.8s;
  max-width: none;
  margin-top: 5px;
}
.workflow-library-nav-block {
  display: flex;
  padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  cursor: pointer;
  height: 82vh;
  overflow-y: auto;
}
.workflow-library-nav-block::-webkit-scrollbar {
  width: 3px;
}
.workflow-library-nav-block::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.workflow-library-nav-block::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}
.workflow-library-nav-item-block {
  display: flex;
  padding: 10px 20px 10px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 95%;
  user-select: none;
  position: relative;
  cursor: pointer;
}
.workflow-library-nav-item-block:hover {
  border-radius: 0px 5px 5px 0px;
  background: #f2f2f2 !important;
}
.workflow-library-nav-item-block.active {
  border-radius: 0px 5px 5px 0px;
  background: #4baaac;
}
.workflow-library-item-headline {
  color: #000;
  text-align: left;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workflow-library-item-headline.active {
  color: #fff;
  font-weight: 700;
}
.workflow-library-side-footer {
  position: relative;
  display: flex;
  padding-bottom: 25px;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
}
.workflow-library-btngroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-top: 35px;
}
.workflow-library-logout-btn {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: var(--green-600-new, #4baaac);
}
.workflow-library-logout-btn-text {
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.workflow-library-logout-btn-icon {
  width: 24px;
  height: 24px;
  display: flex;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
}
.workflow-library-line {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.workflow-library-getsupport-btn {
  display: flex;
  height: 36px;
  padding: 6px 12px;
  align-items: center;
  background-color: #e9ecef;
  gap: 5px;
  border-radius: 5px;
}
.workflow-library-getsupport-btn-text {
  color: var(--Neutral-900, #212121);
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.workflow-library-getsupport-btn-icon {
  width: 16px;
  height: 16px;
  display: flex;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
}
.workflow-library-text {
  color: var(--Neutral-900, #535252);
  text-align: center;
  /* Body 2/Medium */
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
.workflow-library-version-text {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.workflow-library-content-section {
  width: 75%;
  padding-left: 20px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: absolute;
  left: 25%;
  height: 100vh;
  transition: left 0.3s;
}
.workflow-library-content-section.sideb-closed {
  width: 100% !important;
  left: 0;
  padding-left: 0;
}
.workflow-library-header-section-settings {
  display: flex;
  gap: 20px;
}
.workflow-library-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  position: fixed;
  width: 74%;
  background: #f2f2f2;
  z-index: 10;
  right: 6px;
}
.workflow-library-header-full {
  width: 99%;
}
.workflow-library-header-section-title-block {
  display: flex;
  height: 60px;
  gap: 5px;
}
.workflow-library-header-section-title-icon {
  display: flex;
  width: 30px;
  height: 26px;
  background-size: contain;
}
.workflow-library-favorite-section {
  padding: 20px 0px 20px 20px;
  gap: 25px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}
.workflow-library-favorite-section:hover {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.workflow-library-user-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  min-width: fit-content;
}
.workflow-library-user-profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.workflow-library-user-details {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 10px;
}
.workflow-library-header-section-search input {
  width: 250px;
  height: 30px;
  border-radius: 2px;
  background: #dee2e6;
}
.workflow-library-header-section-fav-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.workflow-library-header-section-bin-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.workflow-library-header-section-fav-btn {
  display: flex;
  width: 93px;
  height: 30px;
  padding: 8px 0px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #dee2e6;
}
.workflow-library-header-fav-text {
  color: #686a6f;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.workflow-library-favorite-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.workflow-library-all-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.workflow-library-all-section {
  display: flex;
  height: 65%;
  padding: 20px 10px 20px 20px;
  flex-direction: column;
}
.workflow-library-all-section:hover {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.workflow-library-header-section-title-headline {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  display: contents;
}
.workflow-library-workflow-item {
  width: 300px;
  height: 165px;
  background: lightgray;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  margin: 5px;
}
.workflow-library-workflow-item:hover {
  outline: 4px solid #99830f;
}
.workflow-library-favorit-content-section {
  display: flex;
  gap: 30px;
  overflow-x: hidden;
  height: 200px;
}
.workflow-library-favorit-content-section:hover {
  overflow-x: auto;
}
.workflow-library-favorit-content-section::-webkit-scrollbar {
  height: 6px;
}
.workflow-library-favorit-content-section::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 5px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.workflow-library-favorit-content-section::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}
.workflow-library-all-content-section {
  display: flex;
  padding: 0 26px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 35px;
  flex: 1 0;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-right: 15px;
  margin-top: 25px;
}
.workflow-library-all-content-section:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
}
.workflow-library-all-content-section:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}
.workflow-library-all-content-section::-webkit-scrollbar {
  width: 6px;
}
.workflow-library-all-content-section::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: transparent;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.workflow-library-all-content-section::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: transparent;
}
.workflow-library-carousel {
  position: absolute !important;
}
.workflow-library-carousel > .bottom-5 {
  bottom: 5px;
  z-index: 4;
}
.workflow-library-workflow-item-details-block {
  display: flex;
  height: 125px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  position: absolute;
  z-index: 2;
  width: 100%;
}
.workflow-library-workflow-item-details-heading {
  display: flex;
  padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
}
.workflow-library-workflow-item-details-name {
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
}
.workflow-library-workflow-item-details-name-hover {
  overflow: auto !important;
  text-overflow: unset !important;
  white-space: unset !important;
}
.workflow-library-workflow-item-details-id {
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.workflow-library-workflow-item-details-desc {
  display: flex;
  padding: 0px 5px;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.workflow-library-workflow-item-details-heading-content {
  display: flex;
  padding: 0 5px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  justify-content: space-between;
  width: 100%;
}
.workflow-library-modify-block {
  position: absolute;
  bottom: 0;
}
.workflow-library-modify-block {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
}
.workflow-library-workflow-item-play {
  display: block;
  width: 21px;
  height: 25px;
  cursor: pointer;
  margin: 0 auto;
}
.workflow-library-workflow-item-edit {
  display: block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: 0 auto;
}
.workflow-library-change-arrow {
  display: block;
  width: 32px;
  height: 20px;
  cursor: pointer;
  margin: 0 auto;
  background-position: center;
}
.workflow-library-workflow-item-hover .workflow-library-image-carousel-step {
  width: 16px !important;
  height: 16px !important;
  transition: linear 0.1s;
}
.workflow-library-workflow-item-edit-box {
  font-family: "Poppins Regular", sans-serif;
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.workflow-library-workflow-item-edit-text-box {
  border-radius: 5px !important;
  background: #dee2e6 !important;
  padding: 0px 5px;
  gap: 10px;
  border: none;
  width: 85%;
}
.workflow-library-workflow-item-edit-text-box:focus {
  border: 2px solid #f2d019 !important;
  outline: none !important;
  box-shadow: none !important;
}
.workflow-library-workflow-item-edit-text-star {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.workflow-library-workflow-item-edit-text-box-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  bottom: 10px;
  gap: 230px;
}
.workflow-library-workflow-item-edit-id {
  color: #000;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.workflow-library-workflow-item-delete-message {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  height: 95px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
}
.workflow-library-workflow-item-delete-text-box-footer {
  display: flex;
  width: 280px;
  padding: 0px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.workflow-library-workflow-item-btn {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.workflow-library-workflow-item-btn.yes-btn {
  background: #4baaac !important;
}
.workflow-library-workflow-item-btn.no-btn {
  background: #adb5bd !important;
}
.workflow-library-workflow-item-clicked {
  outline: 4px solid #f2d019 !important;
}
.workflow-library-image-carousel-steps-block {
  width: 70%;
  min-height: 44px;
  display: block;
  z-index: 4;
  text-align: center;
}
.workflow-library-image-carousel-step {
  width: 12px;
  height: 12px;
  background: #d9d9d9;
  border-radius: 50%;
  transition: linear 0.1s;
  margin: 2px;
}
.workflow-library-image-carousel-step.active {
  background-color: #fff;
}
.workflow-library-current-block {
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 300px;
  height: 165px;
  transition: background 0.5s ease;
  border-radius: 5px;
}
.workflow-library-workflow-item-video-label {
  color: #000;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.5215686275);
  border-radius: 5px;
  left: 5px;
  padding: 0 5px;
}
.workflow-library-no-favorit-text {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.workflow-library-item-tooltip {
  bottom: -40px;
  color: #fff;
  position: absolute;
  z-index: 10000;
  background: #333;
  width: 90%;
  padding: 5px;
  border-radius: 4px;
  font-family: "Poppins Regular";
  text-align: center;
  left: 2px;
}
.workflow-library-item-tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent #333 transparent;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}
.workflow-library-page-heading {
  width: 100%;
  color: #6b6f79;
  font-family: "Poppins Regular";
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.workflow-library-page-heading-icon {
  width: 50px;
  height: 16px;
  display: block;
}

.comment-display-container {
  position: absolute;
  z-index: 100;
  left: 15%;
  top: 10%;
  width: 20px;
  display: flex;
  transform: translate(-10px, -50px);
  user-select: none;
}

.comment-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}

.comment-details-title {
  font-family: "Poppins semibold";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin-top: 5px;
}

.comment-details-user-avatar-block {
  display: flex;
  height: 50px;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.comment-details-username {
  color: #000;
  text-align: right;
  font-family: "POPPINS REGULAR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.comment-details-category-block {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  height: 24px;
  z-index: 2;
  position: relative;
}

.comment-selected-category-block {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  height: 24px;
}

.comment-details-dropdown-category {
  position: absolute;
  top: 30px;
  display: flex;
  padding: 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  min-width: 200px;
  height: 65px;
  flex-direction: row;
  background: #fff;
  user-select: none;
}

.comment-details-category-title-selected {
  color: #99830f !important;
}

.comment-details-category-title-block {
  display: flex;
  cursor: pointer;
}

.comment-details-category-title {
  color: #212121;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  user-select: none;
}

.comment-content-block-left-border-without-reply {
  border-left: 12px solid #4baaac;
  border-radius: 5px 5px 5px 5px !important;
}

.comment-content-block-right-border-without-reply {
  border-right: 12px solid #4baaac;
  border-radius: 5px 5px 5px 5px !important;
}

.comment-content-block {
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 5px 5px 5px 5px;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  min-height: 150px;
  max-height: 570px;
  z-index: 2;
  pointer-events: all;
}

.comment-content-block-left-border-create-comment {
  border-left: 12px solid #4baaac;
  border-radius: 5px 5px 5px 5px !important;
}

.comment-content-block-left-border {
  border-left: 12px solid #4baaac;
  border-radius: 5px 0 0 0 !important;
}

.comment-content-block-right-border-create-comment {
  border-right: 12px solid #4baaac;
  border-radius: 5px 5px 5px 5px !important;
}

.comment-content-block-right-border {
  border-right: 12px solid #4baaac;
  border-radius: 0 5px 0 0 !important;
}

.comment-anchor-right {
  transition: 200ms linear;
}

.comment-details-content-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 8px 0;
  gap: 8px;
  max-height: 400px;
  padding: 0 5px 0 5px;
}

.comment-details-record-audio div {
  display: flex;
  align-items: center;
  font-family: "Poppins Regular" !important;
  color: #4baaac !important;
}

.comment-details-description {
  width: 100%;
  color: #212121;
  font-family: "POPPINS REGULAR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  max-height: 400px;
  overflow: auto;
  padding-bottom: 5px;
  line-height: normal;
  padding: 0 5px 0 5px;
  border-radius: 5px;
  border: 1px solid #E2E8F0;
  margin-left: -5px;
}
.comment-details-description::-webkit-scrollbar {
  width: 6px;
  height: none;
}
.comment-details-description::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.comment-details-description::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

.comment-footer-block {
  display: flex;
  padding-right: 10px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  background: #fff;
  padding-left: 10px;
  padding-bottom: 10px;
}

.comment-files-accordion {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.comment-files-accordion-header {
  cursor: pointer !important;
  display: flex;
  padding: 5px 5px 5px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 35px;
  border-bottom: 1px solid rgba(204, 206, 216, 0.7254901961);
}

.comment-files-accordion-content li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 28px;
  position: relative;
}

.comment-files-accordion-content li span {
  display: flex;
  gap: 10px;
}

.comment-files-accordion-content li:last-child {
  border-bottom: none;
}

.comment-files-accordion-content button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.comment-files-accordion-content button:hover {
  background-color: #3e8e41;
}

.comment-files-title {
  color: #000;
  font-family: "Poppins SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.file-count {
  color: var(--Neutral-600, #6b6f79);
  font-family: "Poppins SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 2px;
}

.comment-files-accordion-content {
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.comment-files-accordion-content ul {
  width: 100%;
  padding: 5px;
  list-style: none;
  margin: 0;
  display: none;
}

.comment-files-accordion-content ul.open {
  display: block;
  overflow: auto;
  max-height: 10vh;
  padding: 7px;
}
.comment-files-accordion-content ul.open::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}
.comment-files-accordion-content ul.open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.comment-files-accordion-content ul.open::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.comment-file-name {
  color: #000;
  font-family: "Poppins semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.comment-file-size {
  color: #6b6f79;
  font-family: "POPPINS REGULAR";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.comment-file-thumb {
  width: 30px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: zoom-in;
  border: 1px solid rgba(173, 181, 189, 0.3215686275);
}

.comment-footer-date-block {
  color: #212121;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.comment-details-avatar {
  display: block;
  background-position: top left !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  overflow: hidden;
  background-color: #6b6f79;
}

.comment-details-selected-category {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-comment {
  width: 17px;
  height: 16px;
  display: block;
}

.comment-respond-to,
.comment-edit,
.comment-media-full-slider,
.comment_close_icon,
.comment_check_icon,
.drop_down_arrow_reply,
.drop_down_arrow_reply_open {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  background-position: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: pointer;
}

.icon-download-small {
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

.uploaded_file_file_icon,
.uploaded_file_video_icon {
  width: 30px;
  height: 24px;
}

.drop-down-icon {
  transform: rotate(180deg);
  width: 20px;
  height: 10px;
  display: block;
  transition: 200ms ease-in;
}

.drop-down-icon.open {
  transform: rotate(360deg);
  transition: 200ms ease-out;
}

.comment-action-right, .comment-action-left {
  display: flex;
  flex-direction: column;
  min-width: 40px;
  align-items: center;
  justify-content: flex-end;
}
.comment-action-right:hover, .comment-action-left:hover {
  z-index: 15;
}

.comment-action-left {
  margin: 0px 10px 0 10px;
  pointer-events: all;
}

.comment-action-right {
  margin: 0px 11px 0 11px;
  pointer-events: all;
}

.voice-visualizer {
  display: flex;
  align-items: center;
  width: auto;
}

button.voice-visualizer__btn-left {
  position: relative;
  left: -210px;
  width: 20px;
  height: 20px;
  border: none;
  background: hsl(181.24, 39.27%, 48.43%);
}

.voice-visualizer__btn-left:hover {
  border: none !important;
  background: hsl(181.24, 39.27%, 48.43%) !important;
}

.voice-visualizer__audio-info-container {
  color: var(--Neutral-900, #212121);
  font-family: "Poppins Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: auto !important;
  padding-left: 5px;
}

button.voice-visualizer__btn {
  min-height: auto;
  min-width: auto;
}

.voice-visualizer__buttons-container {
  column-gap: 0 !important;
  row-gap: 0 !important;
  margin-bottom: 0 !important;
}

.comment-annotation-container {
  width: 100%;
  height: 100%;
  z-index: 7;
  position: absolute;
}

.comment-footer-detial {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
  height: 25px;
}

.comment-footer-delete-bin {
  height: 18px;
  width: 14px;
  display: block;
}

.comment-footer-delete-bin:hover {
  cursor: pointer;
}

.comment-anchor-block {
  position: absolute;
}

.comment-anchor-circle {
  display: block;
  position: relative;
  border: #4baaac solid 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5019607843);
  transform: translate(-10px, -10px);
  cursor: pointer;
  transition: 100ms linear;
}

.comment-anchor-circle-hover:hover {
  width: 30px;
  height: 30px;
}

.location-icon-right,
.location-icon-left {
  display: block;
  position: relative;
  top: 40px;
  border: #4baaac solid 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5019607843);
  cursor: pointer;
  z-index: 2;
}

.location-icon-left::after, .location-icon-right::after {
  content: "";
  width: 22px;
  height: 3px;
  background: #4aa7a9;
  display: block;
  position: absolute;
  top: 6px;
}

.location-icon-right::after {
  left: -25px;
}

.location-icon-left::after {
  right: -25px;
}

.comment-create-container {
  position: absolute;
}

.location-icon-right-top {
  transform: rotate(-45deg);
}

.location-icon-left-top {
  transform: rotate(45deg);
}

.comment-content-block-top-right::before {
  display: none;
}

.comment-content-block-top-left::before {
  display: none;
}

.content-file-preview {
  display: flex;
  width: 180px;
  height: 110px;
  align-items: flex-start;
  gap: 10px;
  z-index: 10;
  position: absolute;
  top: 20%;
  left: 12%;
  background-size: cover !important;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.mini-slider {
  position: relative;
  width: 100%;
  max-width: 600px;
  /* adjust based on your needs */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mini-slider-slide img {
  width: 100%;
  display: block;
}

.mini-slider-prev,
.mini-slider-next {
  border: none;
  cursor: pointer;
  z-index: 100;
}

.mini-slider-next:disabled,
.mini-slider-prev:disabled {
  background: transparent !important;
}

.mini-slider-prev {
  left: 10px;
}

.mini-slider-next {
  right: 10px;
}

.mini-slider-indicators {
  display: flex;
  align-items: center;
}

.mini-slider-indicator {
  height: 10px;
  width: 10px;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.mini-slider-indicator.active {
  background-color: #6b6f79;
}

.mini-slider-block-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
}

.mini-slider-block-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 0px 5px 5px 0px;
}

.mini-slider-slide {
  width: 445px;
  max-height: 265px;
  min-height: 265px;
  overflow: auto;
}

.mini-slider-prev-icon {
  width: 24px;
  height: 24px;
  display: block;
}

.mini-slider-next-icon {
  width: 24px;
  height: 24px;
  display: block;
  transform: rotate(180deg);
}

.mini-slider-prev-next-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.mini-slider-back-btn {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 3px;
  cursor: pointer;
}

.mini-slider-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 25px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-right: 8px;
  padding-left: 3px;
}

.back-btn-filename-block {
  display: flex;
  align-items: center;
}

.mini-slider-slides {
  display: flex;
  min-height: 255px;
  align-items: center;
}

.comment-action-center-left {
  transform: translate(0%, 0%);
}

.comment-full-slider-popup > div {
  border-radius: 6px;
  max-height: 100vh;
  max-width: 100vw;
}

.comment-full-slider-popup > div > div {
  background: transparent;
}

.full-slider-close-icon {
  width: 15px;
  height: 15px;
  display: block;
  float: right;
  cursor: pointer;
}

#comment-full-slider-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff !important;
  font-family: "Poppins Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 40px;
}

.full-slider-prev-icon,
.full-slider-next-icon {
  width: 30px;
  height: 30px;
  display: block;
}

.full-slider-prev-icon {
  transform: translateX(-25px);
}

.full-slider-next-icon {
  transform: translateX(25px) rotate(180deg);
}

.slider-content > .pdf-preview {
  height: 80vh;
}

.full-slider-thumb-nail-block {
  justify-content: center;
  display: flex;
  align-items: flex-start;
  gap: 7px;
}

.full-slider-thumb-nail {
  width: 50px;
  height: 35px;
}

.slider-thumbs-block {
  display: flex;
  gap: 7px;
  width: 450px;
  justify-content: center;
  overflow-y: auto;
  padding: 5px;
}
.slider-thumbs-block::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.slider-thumbs-block::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.slider-thumbs-block::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.icon_comment_audio_stop {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-left: 2px;
  cursor: pointer !important;
}

.icon_comment_audio {
  display: flex;
  width: 16px;
  height: 24px;
  background-position: center;
  cursor: pointer;
}

.icon_comment_audio_play {
  display: flex;
  padding: 2px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.icon_comment_audio_pause {
  display: flex;
  padding: 2px 0px;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.comment-details-recorded-audio {
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding: 3px 10px 3px 0px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  height: 24px;
}

.audio_recording {
  color: var(--Green-600-NEW, #4baaac);
  /* Body 2/Medium */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 240% */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.comment_audio_close {
  width: 12px;
  height: 12px;
  display: flex;
  padding: 2px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}

.audio_playing {
  color: var(--Neutral-900, #212121);
  /* Body 2/Medium */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 240% */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.comment_card_audio_icon {
  width: 24px;
  height: 24px;
  margin-left: 15px;
  pointer-events: auto;
}

.comment_card_audio_icon_active {
  width: 24px;
  height: 24px;
  margin-left: 15px;
  pointer-events: auto;
}

.custom-controlbar {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 90% 10%;
  grid-template-areas: "m        m           range" "play     progressbar audio";
  position: relative;
  width: 100%;
  height: 95%;
  z-index: 5;
  cursor: pointer;
}

.controls-progree-audio {
  grid-area: progressbar;
  align-self: center;
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  align-items: center;
  justify-content: center;
}

.custom-audio-range {
  width: 5rem;
  background: transparent;
  background-color: #aceeef;
  accent-color: #aceeef;
  outline: none;
  opacity: 0.7;
  transform: rotate(-90deg);
  transform-origin: 40% 60%;
  cursor: pointer;
  pointer-events: auto;
  margin-bottom: 1.5rem;
}

.comment_card_audio_wrapper {
  grid-area: audio;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: start;
}

.vertical-audio-volume {
  grid-area: range;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  pointer-events: none;
}

.vertical-slider {
  position: relative;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  cursor: pointer;
}

.comment_file_play_icon {
  width: 21px;
  height: 24px;
  margin-right: 20px;
}

.comment_card_audio_play_wrapper {
  grid-area: play;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: end;
  pointer-events: auto;
}

.comment_audio_edit {
  display: flex;
  width: 24px;
  height: 24px;
  padding-bottom: 0.018px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.audio_duration {
  display: contents;
}

.edit {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid var(--Green-600-NEW, #4baaac);
  background: var(--White, #fff);
}

.record {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  background: var(--Neutral-200, #e9ecef);
  justify-content: space-around;
}

.comment-create-container {
  position: absolute;
  z-index: 100;
  width: 20px;
  display: flex;
  transform: translate(-10px, -50px);
  transition: 200ms linear;
}

.comment-details-title-input {
  color: #adb5bd;
  font-family: "Poppins SemiBold" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  border: none;
  padding-left: 0 !important;
  width: 100% !important;
}

.comment-details-title-input:focus {
  border: none !important;
  box-shadow: none !important;
  color: #212121 !important;
  padding-left: 0 !important;
}

.comment-details-description-text-box {
  display: flex;
  padding: 0 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: #212121;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
  resize: none;
}

.comment-details-description-text-box:focus {
  border: none !important;
  box-shadow: none !important;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccced8;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.comment_icon_notice,
.comment_icon_comment,
.comment_icon_ask_ai,
.comment_icon_question,
.comment_icon_notice_selected,
.comment_icon_comment_selected,
.comment_icon_ask_ai_selected,
.comment_icon_question_selected {
  height: 24px;
  width: 24px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

.comment-details-dropdown-category-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding-right: 5px;
  gap: 3px;
}

.notice-icon-block:hover {
  background-color: rgba(231, 76, 60, 0.1254901961);
}

.comment-icon-block:hover {
  background-color: rgba(75, 170, 172, 0.1254901961);
}

.question-icon-block:hover {
  background-color: rgba(110, 130, 254, 0.1254901961);
}

.ask_ai-icon-block:hover {
  background-color: rgba(31, 97, 99, 0.1254901961);
}

.selected-icon-notice {
  background-color: #e74c3c;
}

.selected-icon-comment {
  background-color: #4baaac;
}

.selected-icon-question {
  background-color: #6e82fe;
}

.selected-icon-ask_ai {
  background-color: #1f6163;
}

.comment-details-dropdown-category-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.comment_drop_down_arrow {
  transform: rotate(180deg);
}

.comment_drop_down_arrow,
.comment_drop_down_arrow_selected {
  width: 24px;
  height: 24px;
  display: block;
}

.comment-details-record-audio {
  height: 30px;
  width: 85%;
}

.comment_setting_icon,
.comment_upload_icon,
.comment_setting_icon_active,
.comment_upload_icon_active {
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
}

.comment-footer-detial-feature {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  z-index: 105;
}

.comment-upload-block, .comment-setting-block {
  display: flex;
  padding: 5px;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  translate: 0px -70px;
  z-index: 2;
  flex-direction: column;
}

.comment-setting-block {
  width: 375px;
  height: 160px;
  right: 45px;
  bottom: -30px;
}

.comment-upload-block {
  width: 240px;
  height: 65px;
  right: 15px;
}

.comment-setting-option {
  display: flex;
  text-align: left;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  height: 25px;
}

.comment-upload-option {
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  height: 25px;
}

.comment-upload-option:hover {
  background-color: #e9ecef;
}

.comment-setting-option-text {
  color: #212121;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.comment-text-gray {
  color: #adb5bd;
}

.comment_upload_icon_small,
.comment_picture_video_icon {
  width: 16px;
  height: 17px;
  display: block;
}

.comment_upload_check {
  width: 15px;
  height: 15px;
  background-size: 15px;
  display: block;
}

.ask_ai-left-border-color {
  border-color: #1f6163 !important;
}

.ask_ai-right-border-color {
  border-color: #1f6163 !important;
}

.question-left-border-color {
  border-color: #6e82fe !important;
}

.question-right-border-color {
  border-color: #6e82fe !important;
}

.notice-left-border-color {
  border-color: #e74c3c !important;
}

.notice-right-border-color {
  border-color: #e74c3c !important;
}

.ask_ai-location-color {
  border: #1f6163 solid 3px;
}
.ask_ai-location-color::after {
  background: #1f6163;
}

.notice-location-color {
  border: #e74c3c solid 3px;
}
.notice-location-color::after {
  background: #e74c3c;
}

.question-location-color {
  border: #6e82fe solid 3px;
}
.question-location-color::after {
  background: #6e82fe;
}

.comment-details-block {
  background: #fff;
  border-radius: 5px 0 0 0;
  padding-left: 5px;
  align-self: stretch;
  background: #fff;
}

.delete_uploaded_file {
  width: 15px;
  height: 15px;
  margin: 0px 5px;
  cursor: pointer;
  background-position: center;
}

.file_download_delete {
  display: flex;
  align-items: center;
  gap: 5px;
}

.comment-loader {
  position: absolute;
  z-index: 100;
  width: 500px;
  background: rgba(0, 0, 0, 0.1882352941);
  display: flex;
  align-items: center;
  justify-content: center;
}

.showMoreText {
  color: #4baaac;
  font-family: "POPPINS REGULAR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.mention-input__input {
  background-color: #ffffff !important;
}

.mention-input__input:focus {
  outline: 1px solid #ccced8 !important;
  background: #f2f2f2 !important;
  outline-offset: unset !important;
}

.mention-input__suggestions {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
}

.mention-input__suggestions__list {
  border: none !important;
  border-radius: 5px;
}

.mention-input__suggestions__item {
  border: none !important;
  height: 35px !important;
  color: #212121;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px !important;
}

.mention-input__suggestions__item > div {
  gap: 7px !important;
}

.mention-selected {
  color: #4baaac !important;
  background-color: #fff;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  padding-left: 5px;
  position: relative;
  z-index: 1;
}

span.comment_close_icon.error,
.comment_check_icon.error {
  background-color: #f2d019;
}

.func_for_uploaded_file {
  display: flex;
  align-items: center;
  gap: 5px;
}

.comment-reply-block-right, .comment-reply-block-left {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 10px;
  padding: 8px 10px 0 10px;
}

.comment-reply-block-left {
  direction: rtl;
}

.comment-reply-number {
  color: #6B6F79;
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}

.comment-reply-card {
  transition: 200ms linear;
  max-height: 570px;
  display: flex;
  border-radius: 5px 0 5px 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 200ms linear;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 575px;
  margin-top: 15px;
}

.comment-reply-card-left {
  border-left: 8px solid #4baaac;
  border-radius: 0 0 5px 5px !important;
  margin-right: 5px;
  margin-top: 20px;
}
.comment-reply-card-left::-webkit-scrollbar {
  width: 6px;
}
.comment-reply-card-left::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.comment-reply-card-left::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
  cursor: pointer;
}

.comment-reply-card-right {
  transform: translate(32px, 0px) !important;
  border-radius: 0 0 5px 0 !important;
  margin-left: -30px;
}

.comment-reply-content-block {
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.comment-reply-content-block::-webkit-scrollbar {
  width: 6px;
}
.comment-reply-content-block::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.comment-reply-content-block::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
  cursor: pointer;
}

.comment-reply-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 490px;
  background-color: #fff;
  padding-bottom: 10px;
}

.comment-reply-border {
  border-right: 8px solid #4baaac;
  padding-top: 20px;
}

.comment-reply-border-last {
  border-right: 8px solid #4baaac;
  padding-top: 20px;
  border-radius: 0 0 5px 0 !important;
}

.comment-reply-box .comment-footer-block {
  padding-bottom: 0;
}

.comment-reply-action-center-right {
  display: flex;
}

.comment-reply-action-top-right {
  display: flex;
}

.comment-reply-action-bottom-right {
  display: flex;
}

.comment-reply-action-center-left {
  display: flex;
  transform: translate(0%, 0%);
}

.comment-reply-action-top-left {
  display: flex;
}

.comment-reply-action-bottom-left {
  display: flex;
  transform: translate(0%, 0%);
}

.drop_down_arrow_reply {
  transition: 200ms linear;
  margin-top: 0 !important;
}

.drop_down_arrow_reply_open {
  transform: rotate(180deg);
  transition: 200ms linear;
  margin-top: 0 !important;
}

.comment-conntent-block-reply {
  border-radius: 5px 5px 0px 5px;
}

.comment-reply-main-block {
  display: flex;
  flex-direction: row;
  min-width: 575px;
}

.comment-reply-main-block-left {
  display: flex;
  flex-direction: row;
  min-width: 575px;
  padding-top: 20px;
}

.comment-reply-main-block > .comment-action-left,
.comment-reply-main-block > .comment-action-right {
  transform: unset !important;
}

.comment-content-block-left {
  margin-left: 35px;
  transform: unset;
}

.comment-details {
  display: flex;
  flex-direction: column;
}

.comment-input-category {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 272px;
}

.comment-details-title-input {
  margin: 0;
  padding: 0;
  height: 28px;
  height: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.comment-content-footer {
  background: #fff;
  padding-bottom: 10px;
}

.comment_audio_timer {
  width: 30px;
}

.main-comment-action-top-left {
  display: flex;
  transform: translate(-20px, 75px) !important;
}

.main-comment-action-top-right {
  display: flex;
  transform: translate(-535px, 75px) !important;
}

.main-comment-action-bottom-left {
  display: flex;
  transform: translate(0%, -30%) !important;
}

.main-comment-action-bottom-right {
  display: flex;
  transform: translate(-98%, -30%) !important;
}

.main-comment-action-center-right {
  display: flex;
  transform: translate(-550px, 10px) !important;
}

.main-comment-action-center-left {
  display: flex;
}

.mention-input__input:focus {
  outline: 1px solid #f2f2f2 !important;
}

.comment-footer-icons {
  display: flex;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 3px;
  flex-shrink: 0;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 10px;
}

.toggleright-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.share-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.settings-container {
  background: #fff;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  gap: 50px;
  margin-top: 87px;
  height: -webkit-fill-available;
}
.settings-navbar {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  flex-wrap: nowrap;
  width: 350px;
}
.settings-nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}
.settings-nav-item {
  display: inline;
  margin: 0 15px;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s;
  font-family: "Poppins Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  min-height: 50px;
}
.settings-nav-links-text {
  padding: 5px 10px;
  width: 300px;
}
.settings-nav-links-text:hover {
  background-color: #f2f2f2;
  color: #4baaac;
}
.settings-nav-active {
  color: #4baaac;
}
.settings-section {
  width: 60%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  overflow: auto;
}
.settings-section::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.settings-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.settings-section::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}
.settings-password-section {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.settings-nav-item.active {
  color: #4baaac;
}
.settings-submenu-item {
  font-size: 14px !important;
  color: #000;
  margin-left: 20px;
  width: 300px;
  padding: 5px 10px;
}
.settings-submenu-item:hover {
  background-color: #f2f2f2;
  color: #4baaac;
}
.settings-submenu-item.active {
  color: #4baaac;
}
.settings-password-block {
  width: 405px;
}
.settings-feild-label {
  font-family: "Poppins Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  height: 42px;
}

.general-input-field-invalid-error {
  color: rgb(242, 208, 25);
  font-family: "Poppins Regular";
  font-size: 14px;
}
.general-input-field-block {
  margin-bottom: 20px;
}
.general-input-field::placeholder {
  color: #adb5bd;
}
.general-input-field:focus {
  background: #f2f2f2;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px;
}
.general-input-field {
  display: inline-flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: none;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 300px;
}
.general-input-name-lastname-section {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccced8;
  padding-bottom: 20px;
  gap: 20px;
}
.general-input-phone-email-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.general-input-profession-section {
  margin-top: 40px;
}

.save-settings-btn {
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #4baaac;
  font-family: "Poppins Medium";
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 95px;
  margin-bottom: 5px;
}

.settings-new-password-block {
  margin-top: 100px;
  padding: 5px;
}

.settings-old-password-block {
  padding: 5px;
}

.general-password-field-fill {
  background-color: rgb(242, 242, 242) !important;
  outline: 1px solid #ccced8 !important;
  box-shadow: 0 2px 2px 0 rgb(242, 242, 242) !important;
  border-radius: 5px !important;
}

ul.settings-password-check {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
ul.settings-password-check > li {
  align-items: center !important;
  display: inline-flex !important;
  font-family: "Poppins Regular", sans-serif !important;
  font-size: 14px !important;
  margin: 2px 4px !important;
  line-height: 24px;
}

ul.settings-password-check > li > span {
  opacity: 1 !important;
}

.invalid > svg > path {
  fill: #d90018 !important;
}

.valid > svg > path {
  fill: #19eef2 !important;
}

.setting-password-checklist-icon {
  margin-right: 10px;
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* Spacing between elements */
}

.counter-button {
  padding: 5px 10px;
  border: none;
  background-color: #4baaac;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  width: 35px;
}

.counter-button:hover {
  background-color: #4baaac;
}

.counter-input {
  width: 60px;
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.counter-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.counter-title {
  margin-bottom: 5px;
  font-family: "Poppins Regular";
  font-size: 15px;
  font-weight: bold;
  color: #333;
}

.image-edit-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  top: 15px;
  position: relative;
}

.image-edit-settins-block {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.upload-media-block {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.selected-avatar-icon {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  contain: content;
}

.workflow-library-item-list-setting-icon {
  width: 32px;
  height: 32px;
  display: flex;
  width: 20%;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
}

.settings-separator {
  display: block;
  width: 100%;
  height: 1px;
  background: #ccced8;
  margin-top: 37px;
}

.settings-forgot-password-btn {
  display: flex;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  font-family: "Poppins Medium";
  border-radius: 6px;
  color: #fff;
  background: rgb(75, 170, 172);
  line-height: 15px;
}

.settings-buttons-block {
  display: flex;
  gap: 10px;
}

span.delete_account_message {
  font-size: 14px;
  font-family: "Poppins Regular";
  line-height: 24px;
  height: 85px;
  display: block;
}

.delete_account_delete_button {
  width: 150px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: rgb(242, 242, 242);
  font-family: "Poppins Regular";
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.delete_account_delete_button:hover {
  background: rgb(75, 170, 172);
  color: #fff;
}

.delete-account-section {
  margin-top: 25px;
}

.delete-account-message-block {
  margin-bottom: 20px;
}

.delete-account-heading {
  font-family: "Poppins Regular";
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: rgb(0, 0, 0);
}

.delete-account-body-text {
  font-family: "Poppins Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgb(0, 0, 0);
  margin-top: 30px;
  height: 50px;
}

.delete-account-button-confirm {
  height: 36px;
  padding: 6px 12px 6px 12px;
  gap: 5px;
  border-radius: 6px;
  background-color: rgb(217, 0, 24) !important;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
}

.delete-account-button-cancel {
  width: 100px;
  height: 36px;
  padding: 6px 12px 6px 12px;
  gap: 0px;
  border-radius: 6px;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background: rgb(233, 236, 239) !important;
  color: rgb(33, 33, 33);
}

button.delete-account-button-confirm:disabled {
  background: rgb(173, 181, 189) !important;
  color: rgb(255, 255, 255);
}

.delete-account-form-text {
  font-family: "Poppins Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}

.delete-account-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.delete-account-form-input-section {
  display: flex;
  align-items: center;
}

label.delete-account-form-label {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-right: 10px;
  text-align: left;
  flex: 0 0 100px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.reset-account-form-label {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-right: 10px;
  text-align: left;
}

.reset-password-form-input-section {
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
}

.general-input-professional-section,
.general-input-name-lastname-section,
.general-input-profession-section,
.general-input-phone-email-section,
.general-input-password-section {
  margin-bottom: 30px;
}

.delete-account-form-block {
  display: flex;
  flex-direction: column;
}

.delelte-confirm-logo {
  width: 260px;
  height: 50px;
  margin-top: 90px;
}

.delete-confirm-heading {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 10px;
}

.delete-confirm-body-text {
  color: #000;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 423px;
}

.delete-confirm-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}

.reset-password-button {
  display: flex;
  height: 36px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #4baaac !important;
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.setting-menu-headline {
  color: #adb5bd;
  font-family: "Poppins Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  transition-duration: 0.3s;
}

.workflow-library-item-list-setting-icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  transition-duration: 0.3s;
}

.workflow-library-nav-item-block.active .setting-menu-headline {
  color: #fff;
}

.workflow-library-nav-item-block:hover .setting-menu-headline {
  color: #000 !important;
}

.workflow-library-nav-item-block_transition {
  transition-duration: 0.3s;
}

:root {
  --clr-hightlight-100: hsl(180, 100%, 10%);
  --clr-hightlight-200: hsl(180, 100%, 20%);
  --clr-hightlight-300: hsl(180, 100%, 30%);
  --clr-hightlight-400: hsl(180, 100%, 40%);
  --clr-hightlight-500: hsl(180, 100%, 50%);
  --clr-hightlight-600: hsl(180, 100%, 60%);
  --clr-hightlight-700: hsl(180, 100%, 80%);
  --clr-hightlight-800: hsl(180, 100%, 80%);
  --clr-hightlight-900: hsl(180, 100%, 90%);
  --clr-primary-100: hsl(0, 0%, 12%);
  --clr-primary-200: hsl(0, 0%, 20%);
  --clr-primary-300: hsl(0, 0%, 30%);
  --clr-primary-400: hsl(0, 0%, 40%);
  --clr-primary-500: hsl(0, 0%, 50%);
  --clr-primary-600: hsl(0, 0%, 60%);
  --clr-primary-700: hsl(0, 0%, 70%);
  --clr-primary-800: hsl(0, 0%, 80%);
  --clr-primary-900: hsl(0, 0%, 90%);
  --clr-condenced-100: hsl(180, 35%, 15%);
  --clr-condenced-200: hsl(180, 35%, 25%);
  --clr-condenced-300: hsl(180, 35%, 35%);
  --clr-condenced-400: hsl(181, 39%, 48%);
  --clr-condenced-500: hsl(180, 35%, 55%);
  --clr-condenced-600: hsl(180, 35%, 65%);
  --clr-condenced-700: hsl(180, 35%, 75%);
  --clr-condenced-800: hsl(180, 35%, 85%);
  --clr-condenced-900: hsl(180, 35%, 95%);
  --clr-secondary-100: hsl(50, 82%, 13%);
  --clr-secondary-200: hsl(50, 82%, 23%);
  --clr-secondary-300: hsl(50, 82%, 33%);
  --clr-secondary-400: hsl(50, 82%, 43%);
  --clr-secondary-500: hsl(50, 82%, 53%);
  --clr-secondary-600: hsl(50, 82%, 63%);
  --clr-secondary-700: hsl(50, 82%, 73%);
  --clr-secondary-800: hsl(50, 82%, 83%);
  --clr-secondary-900: hsl(50, 82%, 93%);
  --clr-colors-links-youtube: #d16e8d;
  --clr-colors-links-articles: #ffac83;
  --clr-colors-links-community: #3f78c9;
  --clr-colors-links-courses: #49c4a3;
  --clr-icon-colors-need-bg-color: #81d3f8;
  --clr-icon-colors-pain-bg-color: #facd91;
  --clr-icon-colors-error-bg-color: #ec808d;
  --clr-icon-colors-comment-bg-color: #7f7f7f;
  --clr-icon-colors-request-bg-color: #caf982;
  --clr-icon-colors-idea-bg-color: #c381ff;
  --clr-icon-colors-todo-bg-color: #ffff80;
  --clr-flat-colors-black-color: #000;
  --clr-flat-colors-white-color: #fff;
  --clr-flat-colors-red-color: #f00;
  --clr-flat-colors-green-color: #0f0;
  --clr-flat-colors-blue-color: #00f;
  --fs-300: 1rem;
  --fs-400: 1.125rem;
  --fs-500: 1.563rem;
  --fs-600: 1.953rem;
  --fs-700: 2.441rem;
  --fs-800: 3.052rem;
}
@media only screen and (min-width: 40em) {
  :root {
    --fs-300: 1rem;
    --fs-400: 1.425rem;
    --fs-500: 2rem;
    --fs-600: 2.827rem;
    --fs-700: 4rem;
    --fs-800: 5.653rem;
  }
}

.text-hightlight-100 {
  color: hsl(180, 100%, 10%);
}

.bg-hightlight-100 {
  color: hsl(180, 100%, 10%);
}

.text-hightlight-200 {
  color: hsl(180, 100%, 20%);
}

.bg-hightlight-200 {
  color: hsl(180, 100%, 20%);
}

.text-hightlight-300 {
  color: hsl(180, 100%, 30%);
}

.bg-hightlight-300 {
  color: hsl(180, 100%, 30%);
}

.text-hightlight-400 {
  color: hsl(180, 100%, 40%);
}

.bg-hightlight-400 {
  color: hsl(180, 100%, 40%);
}

.text-hightlight-500 {
  color: hsl(180, 100%, 50%);
}

.bg-hightlight-500 {
  color: hsl(180, 100%, 50%);
}

.text-hightlight-600 {
  color: hsl(180, 100%, 60%);
}

.bg-hightlight-600 {
  color: hsl(180, 100%, 60%);
}

.text-hightlight-700 {
  color: hsl(180, 100%, 80%);
}

.bg-hightlight-700 {
  color: hsl(180, 100%, 80%);
}

.text-hightlight-800 {
  color: hsl(180, 100%, 80%);
}

.bg-hightlight-800 {
  color: hsl(180, 100%, 80%);
}

.text-hightlight-900 {
  color: hsl(180, 100%, 90%);
}

.bg-hightlight-900 {
  color: hsl(180, 100%, 90%);
}

.text-primary-100 {
  color: hsl(0, 0%, 12%);
}

.bg-primary-100 {
  color: hsl(0, 0%, 12%);
}

.text-primary-200 {
  color: hsl(0, 0%, 20%);
}

.bg-primary-200 {
  color: hsl(0, 0%, 20%);
}

.text-primary-300 {
  color: hsl(0, 0%, 30%);
}

.bg-primary-300 {
  color: hsl(0, 0%, 30%);
}

.text-primary-400 {
  color: hsl(0, 0%, 40%);
}

.bg-primary-400 {
  color: hsl(0, 0%, 40%);
}

.text-primary-500 {
  color: hsl(0, 0%, 50%);
}

.bg-primary-500 {
  color: hsl(0, 0%, 50%);
}

.text-primary-600 {
  color: hsl(0, 0%, 60%);
}

.bg-primary-600 {
  color: hsl(0, 0%, 60%);
}

.text-primary-700 {
  color: hsl(0, 0%, 70%);
}

.bg-primary-700 {
  color: hsl(0, 0%, 70%);
}

.text-primary-800 {
  color: hsl(0, 0%, 80%);
}

.bg-primary-800 {
  color: hsl(0, 0%, 80%);
}

.text-primary-900 {
  color: hsl(0, 0%, 90%);
}

.bg-primary-900 {
  color: hsl(0, 0%, 90%);
}

.text-condenced-100 {
  color: hsl(180, 35%, 15%);
}

.bg-condenced-100 {
  color: hsl(180, 35%, 15%);
}

.text-condenced-200 {
  color: hsl(180, 35%, 25%);
}

.bg-condenced-200 {
  color: hsl(180, 35%, 25%);
}

.text-condenced-300 {
  color: hsl(180, 35%, 35%);
}

.bg-condenced-300 {
  color: hsl(180, 35%, 35%);
}

.text-condenced-400 {
  color: hsl(181, 39%, 48%);
}

.bg-condenced-400 {
  color: hsl(181, 39%, 48%);
}

.text-condenced-500 {
  color: hsl(180, 35%, 55%);
}

.bg-condenced-500 {
  color: hsl(180, 35%, 55%);
}

.text-condenced-600 {
  color: hsl(180, 35%, 65%);
}

.bg-condenced-600 {
  color: hsl(180, 35%, 65%);
}

.text-condenced-700 {
  color: hsl(180, 35%, 75%);
}

.bg-condenced-700 {
  color: hsl(180, 35%, 75%);
}

.text-condenced-800 {
  color: hsl(180, 35%, 85%);
}

.bg-condenced-800 {
  color: hsl(180, 35%, 85%);
}

.text-condenced-900 {
  color: hsl(180, 35%, 95%);
}

.bg-condenced-900 {
  color: hsl(180, 35%, 95%);
}

.text-secondary-100 {
  color: hsl(50, 82%, 13%);
}

.bg-secondary-100 {
  color: hsl(50, 82%, 13%);
}

.text-secondary-200 {
  color: hsl(50, 82%, 23%);
}

.bg-secondary-200 {
  color: hsl(50, 82%, 23%);
}

.text-secondary-300 {
  color: hsl(50, 82%, 33%);
}

.bg-secondary-300 {
  color: hsl(50, 82%, 33%);
}

.text-secondary-400 {
  color: hsl(50, 82%, 43%);
}

.bg-secondary-400 {
  color: hsl(50, 82%, 43%);
}

.text-secondary-500 {
  color: hsl(50, 82%, 53%);
}

.bg-secondary-500 {
  color: hsl(50, 82%, 53%);
}

.text-secondary-600 {
  color: hsl(50, 82%, 63%);
}

.bg-secondary-600 {
  color: hsl(50, 82%, 63%);
}

.text-secondary-700 {
  color: hsl(50, 82%, 73%);
}

.bg-secondary-700 {
  color: hsl(50, 82%, 73%);
}

.text-secondary-800 {
  color: hsl(50, 82%, 83%);
}

.bg-secondary-800 {
  color: hsl(50, 82%, 83%);
}

.text-secondary-900 {
  color: hsl(50, 82%, 93%);
}

.bg-secondary-900 {
  color: hsl(50, 82%, 93%);
}

.text-colors-links-youtube {
  color: #d16e8d;
}

.bg-colors-links-youtube {
  color: #d16e8d;
}

.text-colors-links-articles {
  color: #ffac83;
}

.bg-colors-links-articles {
  color: #ffac83;
}

.text-colors-links-community {
  color: #3f78c9;
}

.bg-colors-links-community {
  color: #3f78c9;
}

.text-colors-links-courses {
  color: #49c4a3;
}

.bg-colors-links-courses {
  color: #49c4a3;
}

.text-icon-colors-need-bg-color {
  color: #81d3f8;
}

.bg-icon-colors-need-bg-color {
  color: #81d3f8;
}

.text-icon-colors-pain-bg-color {
  color: #facd91;
}

.bg-icon-colors-pain-bg-color {
  color: #facd91;
}

.text-icon-colors-error-bg-color {
  color: #ec808d;
}

.bg-icon-colors-error-bg-color {
  color: #ec808d;
}

.text-icon-colors-comment-bg-color {
  color: #7f7f7f;
}

.bg-icon-colors-comment-bg-color {
  color: #7f7f7f;
}

.text-icon-colors-request-bg-color {
  color: #caf982;
}

.bg-icon-colors-request-bg-color {
  color: #caf982;
}

.text-icon-colors-idea-bg-color {
  color: #c381ff;
}

.bg-icon-colors-idea-bg-color {
  color: #c381ff;
}

.text-icon-colors-todo-bg-color {
  color: #ffff80;
}

.bg-icon-colors-todo-bg-color {
  color: #ffff80;
}

.text-flat-colors-black-color {
  color: #000;
}

.bg-flat-colors-black-color {
  color: #000;
}

.text-flat-colors-white-color {
  color: #fff;
}

.bg-flat-colors-white-color {
  color: #fff;
}

.text-flat-colors-red-color {
  color: #f00;
}

.bg-flat-colors-red-color {
  color: #f00;
}

.text-flat-colors-green-color {
  color: #0f0;
}

.bg-flat-colors-green-color {
  color: #0f0;
}

.text-flat-colors-blue-color {
  color: #00f;
}

.bg-flat-colors-blue-color {
  color: #00f;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: "Poppins Regular";
}

@media screen and (max-width: 320px) {
  .main_pic_slider_block {
    flex-direction: column;
  }
  .all_comments_list {
    height: auto !important;
  }
  .container_recaptcha_reset, .container_recaptcha_register, .container_recaptcha_login, .container_recaptcha_forgot {
    -webkit-transform: scale(0.77);
    -moz-transform: scale(0.77);
    transform: scale(0.77);
  }
  .container-signup-block, .block-reset, .block-signup-verify, .block-signup-success, .block-login {
    padding: 15px 15px 15px 5px;
  }
  #label_reset-password_description {
    padding: 40px 0 10px !important;
  }
  .workflow-library-item-list-icon {
    display: none !important;
  }
  .workflow-library-favorite-header-section,
  .workflow-library-all-header-section {
    flex-direction: column !important;
    align-items: start !important;
  }
  .workflow-library-header-section-settings {
    flex-direction: column !important;
    align-items: start !important;
  }
}
@media screen and (max-width: 768px) {
  .player_sidebar {
    top: 0 !important;
  }
  .switch_role_body {
    height: 250px !important;
  }
  .switch_role_roles_section.flex {
    flex-direction: column;
  }
  .switch_role_select_role {
    margin: 60px auto;
  }
  .input-style, .customize-input-style, #password_login, #email_login {
    margin: 0 auto;
    width: 275px !important;
  }
  #label_sign_up_conditions_text {
    padding: 15px 0 0 20px;
  }
  .button_modal_back, .card_role_switcher_background {
    width: 370px !important;
  }
  .user_role_image_verfiy, .user_role_image_rest_success, .user_role_image_reset, .user_role_image_register_success, .user_role_image_register, .user_role_image_login, .input_icon {
    display: none;
  }
  .input_label, .input_group {
    text-align: center;
  }
  .input_group {
    margin-top: 20px !important;
  }
  .verify_modal_content, .reset_passwor_modal_content {
    width: auto !important;
  }
  .reset-form {
    margin-top: 95px !important;
  }
  .workflow-library-item-list-icon {
    display: none !important;
  }
  .workflow-library-side-footer {
    flex-direction: column !important;
  }
  .workflow-library-item-tooltip {
    bottom: -60px !important;
  }
  .container_process {
    top: 0 !important;
  }
}
@media screen and (max-width: 1024px) {
  .user_role_image_verfiy, .user_role_image_reset_success, .user_role_image_reset, .user_role_image_register_success, .user_role_image_register, .user_role_image_login {
    display: none !important;
  }
  .container_process {
    height: 100%;
  }
  .video_player_block {
    flex: 100% 1;
    position: relative;
  }
  .task-container {
    max-height: 13vmax !important;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 6px;
}
html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #ada9a9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #738585;
}

.noInfoMessage {
  font-family: "Poppins Regular";
  font-family: "Poppins Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.videoNotificationInfo {
  font-family: "Poppins Regular";
  font-family: "Poppins Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.workflow_side_infobar_transition {
  transition: background-color 0.2s;
}

.infobar_transition {
  transition-duration: 0.3s;
}

.workflow_library_transition {
  transition-duration: 0.5s;
}

.workflow_item_transition {
  transition-duration: 0.1s;
}

.landing_header_transition {
  transition-duration: 0.2s;
}

.clickLink {
  cursor: pointer;
}

#label_sign_up_conditions_text, .input_label, #label_text_link-log-in, #label_login_description, #label_sign-up_description, #label_sign-up_header {
  box-sizing: border-box;
  line-height: normal;
  text-align: center;
}

#label_sign-up_header {
  background-color: hsla(0, 0%, 100%, 0);
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 32px;
  font-weight: 700;
}

#label_login_description, #label_sign-up_description {
  background-color: hsla(0, 0%, 100%, 0);
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  font-size: 26px;
}

#label_sign-up_description {
  margin-top: 20px;
}

#label_login_description {
  margin-top: 15px;
  padding-top: 20px;
}

#label_text_link-log-in {
  background-color: hsla(0, 0%, 100%, 0);
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-top: 0;
}

#label_text_link-log-in_a {
  color: hsl(181, 39%, 48%);
}

.input_field {
  background-color: #fff;
  border: 2px solid hsl(0, 0%, 90%);
  border-radius: 5px;
  box-sizing: border-box;
  color: hsl(0, 0%, 20%);
  font-family: "Poppins Regular", sans-serif;
  height: 45px;
  padding: 2px 2px 2px 16px;
  text-align: left;
  width: 600px;
}

.input_label {
  background-color: hsla(0, 0%, 100%, 0);
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
}

input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
  -moz-box-shadow: inset 0 0 0 30px #fff !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.customize-input-style, .input-style {
  border-width: 2px !important;
  font-family: "Poppins Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  height: 45px;
  letter-spacing: normal;
  padding: 2px 2px 2px 16px;
  text-align: left;
  text-transform: none;
  vertical-align: none;
  width: 770px;
}

.customize-input-style:focus, .input-style:focus {
  background-color: hsl(0, 0%, 90%) !important;
}

.input-style {
  background-color: initial;
  border-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
}
.input:focus {
  background-color: #fff;
  border: 2px solid #1e98d7;
}

.boldOnly, .boldColored {
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
}

.boldColored {
  color: hsl(181, 39%, 48%);
}

.input_icon {
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: inline-block;
  flex-grow: 0.3;
  height: 32px;
  left: -50px;
  position: relative;
  top: 70px;
  width: 40px;
}

.normal_control_icon_user_account {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 80px;
  padding: 2px;
  position: absolute;
  width: 80px;
}

.input_group {
  display: table;
  margin: 0 auto;
  margin-top: -15px !important;
}

.input_group_item {
  flex-grow: 9;
  display: contents;
  flex-direction: column;
  width: 670px;
}

.default-label-style {
  display: inline;
  font-family: "Poppins Regular", sans-serif;
  font-size: 12px;
}

#label_sign_up_conditions_text {
  background-color: hsla(0, 0%, 100%, 0);
  color: hsl(0, 0%, 40%);
  font-family: "Poppins Regular", sans-serif;
  font-size: 10px;
  padding-top: 15px;
  text-align: center;
  width: 100%;
}

.show-hide-pass {
  padding-bottom: 15px;
}

.signup-button-block {
  margin-top: 10px !important;
}

.signup-user-account {
  left: -40px;
  top: -9px;
}

.login-user-account {
  margin: 0 auto;
  position: relative;
  top: -60px;
}

.main_player_block {
  display: flex;
}

.picture_slider_main_slide {
  float: right;
}

.video_player_block {
  background: #fff;
  flex: 65%;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
}

.global-time-line-block {
  position: relative;
  padding: 20px 0;
  background: #fff;
  width: 100%;
}

.player_sidebar {
  background-color: #f2f2f2;
  box-shadow: 0 -5px 5px 0 hsl(0, 0%, 12%);
  flex: 30%;
  z-index: 6;
  height: auto;
  position: relative;
}

#search {
  border-radius: 0;
  border: 2px solid transparent;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 30px;
}
#search:focus {
  border: 2px solid #99830f;
  box-shadow: none;
  background-color: hsl(0, 0%, 90%);
}

.picture_slider_flex_row {
  clear: both;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.thumbnail-block {
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

.headerLogo {
  display: inline;
  right: 11px;
  width: 45px;
}

.profile-pic {
  position: absolute;
  right: 20px;
}

.header {
  background: hsl(0, 0%, 90%);
}

.icon_search {
  opacity: 0.5;
}

.icon_filter_category {
  z-index: 10;
  background-position: center;
}

.icon_sort_category, .icon_filter_person, .icon_filter_notify_active, .icon_filter_notify, .icon_filter_category {
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-position: center;
}

.icon_filter_person {
  background-position: center;
}

.icon_filter_person, .icon_filter_notify_active, .icon_filter_notify {
  z-index: 8;
}

.icon_filter_person_select {
  z-index: 11;
}

.icon_filter_notify_select, .icon_filter_notify_active_select {
  z-index: 12;
}

.filter_person_item_container {
  width: 100%;
  width: 90%;
}

.filter_person_item_container-hover, .filter_person_item_container {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  text-align: center;
}

.filter_person_item_container-hover {
  background-color: hsl(180, 35%, 25%);
  border-bottom: 2px solid #fff;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  width: 100%;
}

.icon_sort_category {
  z-index: 8;
}

.icon_sort_category_select {
  z-index: 11;
}

.filter_block, .sort_block {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding: 2px;
  position: absolute;
  top: -4px;
  -webkit-user-select: none;
  user-select: none;
  width: 167px;
}

.sort_block {
  right: 0;
  z-index: 10;
}

.filter_block {
  right: 0;
  z-index: 9;
}

.context_menu_header {
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-weight: 600;
  line-height: normal;
  width: auto;
}

.context_menu_item, .context_menu_header {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-size: 14px;
  text-align: left;
}

.context_menu_item {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: "Poppins Regular", sans-serif;
  height: 20px;
  opacity: 0.9;
}

.filters_block {
  border-bottom: 2px solid hsl(0, 0%, 40%);
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-bottom: 5px;
  position: relative;
  text-align: right;
  min-height: 35px;
}

.filter-icons {
  display: flex;
}

.filter-section {
  margin-left: 8px;
  position: relative;
  padding-bottom: 3px;
  width: 20px;
  justify-content: center;
  margin-right: 0px;
  border-bottom: 2px solid transparent;
  height: fit-content;
}
.filter-section:has(.icon_filter_notify:hover) {
  border-bottom: 2px solid #99830f;
}
.filter-section:has(.icon_filter_person:hover) {
  border-bottom: 2px solid #99830f;
}
.filter-section:has(.icon_filter_category:hover) {
  border-bottom: 2px solid #99830f;
}

.all-category-icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  opacity: 0.8;
  position: relative;
}

.changeMenuItemSize {
  font-weight: 600;
}

.block_icon {
  display: flex;
  width: 80px;
}

.container_icon {
  height: 19px;
  margin-right: 2px;
  padding: 3px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.all_categoryBgColor {
  background-color: hsl(0, 0%, 80%);
}

.needBgColor {
  background-color: #81d3f8;
}

.painBgColor {
  background-color: #facd91;
}

.errorBgColor {
  background-color: #ec808d;
}

.commentBgColor {
  background-color: #4baaac;
}

.requestBgColor {
  background-color: #caf982;
}

.IdeaBgColor {
  background-color: #c381ff;
}

.todoBgColor {
  background-color: #ffff80;
}

.noticeBgColor {
  background-color: #e74c3c;
}

.ask_aiBgColor {
  background-color: #1f6163;
}

.questionBgColor {
  background-color: #6e82fe;
}

.comment_icon_all_category_selected {
  width: 15px;
  height: 12px;
  background-repeat: no-repeat;
}

.icon_need {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
  height: 13px;
  width: 13px;
}

.icon_pain {
  height: 14px;
  width: 8px;
}

.icon_error {
  height: 14px;
  width: 10px;
  margin-right: 0 !important;
}

.icon_comment {
  height: 14px;
  margin-top: 1.5px;
  width: 13px;
}

.icon_request {
  height: 14px;
  width: 8px;
  margin-right: 0 !important;
}

.icon_Idea {
  height: 14px;
  width: 11px;
}

.icon_todo {
  height: 12px;
  width: 16px;
}

.selected_tags {
  width: auto;
}

.selected_tags, .comments_cats {
  display: flex;
  flex-wrap: wrap;
  min-height: 19px;
}

.comments_cats {
  position: relative;
}

.comments_cats {
  width: 100%;
}

.arrow-up-block, .arrow-down-block {
  margin-left: auto;
  padding-top: 3px;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.selected_tag_need {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
  height: 11px;
  margin-top: 0.5px;
  width: 11px;
}

.selected_tag_problem {
  height: 11px;
  margin-top: 0.5px;
  width: 7px;
}

.selected_tag_close {
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 2px;
}
.selected_tag_close:hover {
  cursor: pointer;
}

.selected_tag_text {
  color: hsl(0, 0%, 12%);
  display: flex;
  font-family: "Poppins SemiBold", "Poppins Regular", sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin-left: 1px;
  margin-right: 3px;
  text-align: left;
  text-transform: capitalize;
}

.selected_tag_container {
  margin-right: 3px;
  min-height: 22px;
  min-width: 22px;
  padding: 3px;
}

.notify_block {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  height: 435px;
  padding: 2px;
  position: absolute;
  right: 0;
  top: -4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 300px;
  z-index: 11;
}

.notify_box_comment_text {
  background: hsl(0, 0%, 90%);
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  width: 85%;
}

.notify_box_message {
  border-bottom: 1px solid #000;
  margin-bottom: 3px;
  padding: 5px;
  text-align: left;
}

.annotation_comment_box {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 7px;
  width: 330px;
  z-index: 6;
}

.annotation_comment_box_headline {
  display: flex;
  flex-direction: row;
}

.annotation_comment_box_headline_title {
  font-size: 15px;
  font-weight: 400;
  width: 45%;
}

.annotation_comment_box_text {
  padding: 5px;
  text-align: left;
}

.annotation_comment_text {
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  text-align: left;
  width: 100%;
}

.annotation_comment_box_replies {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.annotation_comment_box_reply_headline {
  border-bottom: 1px solid #000;
  font-size: 10px;
  margin-top: 2px;
  text-align: left;
  width: 100%;
}

.annotation_comment_box_reply_input {
  background-color: hsl(0, 0%, 90%);
  border: 1px solid #000;
  border-radius: 2px;
  height: 80px;
  padding: 3px;
  text-align: left;
  width: 100%;
}

.annotation_comment_box_reply_text {
  background-color: hsl(0, 0%, 90%);
  border-radius: 5px;
  font-size: 12px;
  height: auto;
  margin: 2px;
  max-height: 50px;
  min-height: 15px;
  overflow: auto;
  padding: 5px;
  text-align: left;
  width: 100%;
}

.annotation_comment_box_text, .annotation_comment_box_reply, .annotation_comment_box_replies {
  font-size: 12px;
  height: auto;
  margin-top: 5px;
  max-height: 150px;
  width: 100%;
}

.notify_box_message, .notify_box_comment_text, .filter_person_item_container, .char_detected_list_element, .annotation_comment_text, .annotation_comment_boy_reply_input, .annotation_comment_box_text, .annotation_comment_box_reply_text, .annotation_comment_box_reply_headline, .annotation_comment_box_reply, .annotation_comment_box_replies, .annotation_comment_box_headline_title, .annotation_comment_box_created, .annotation_comment_box_changed {
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  text-align: left;
}

.annotation_comment_box_created, .annotation_comment_box_changed {
  font-size: 9px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: left;
  white-space: pre-line;
  width: 20%;
}

.annotation_comment_box_user_image {
  width: 15%;
}

.suggestion_comment_box_user_image {
  margin: 0 5px;
}

.comment_user_display_name {
  position: absolute;
  right: 30px;
  top: 50px;
  font-family: "Poppins Regular";
  color: hsl(0, 0%, 40%);
}

.suggestion_user_profile {
  height: 25px;
  position: relative;
  width: 25px;
}

.filter_person_box_user_image {
  margin: 5px;
}

.filter_person_user_profile {
  height: 25px;
  position: relative;
  width: 25px;
}

.char_detected_list {
  background-color: azure;
  display: block;
  left: 30px;
  padding: 3px;
  position: absolute;
  width: 130px;
}

.char_detected_list_element-hover, .char_detected_list_element {
  align-items: center;
  display: flex;
  height: 25px;
  padding-left: 5px;
}

.char_detected_list_element-hover {
  background-color: hsl(180, 100%, 60%);
}

.comment_side_box_transition {
  transition-duration: 0.3s;
}

.comment_side_box {
  z-index: 100;
  overflow: auto;
  padding: 7px;
}
.comment_side_box::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.comment_side_box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.comment_side_box::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.comment_side_box.large, .comment_side_box {
  background-color: #fff;
  border-radius: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
  padding: 7px;
}

.comment_side_box {
  box-shadow: 0px 3px 2px -1px rgba(33, 37, 41, 0.25);
}
.comment_side_box:hover {
  box-shadow: 1px 1px 6px 3px rgba(33, 37, 41, 0.25);
}

.comment_side_box.large {
  box-shadow: 0px 3px 2px -1px rgba(33, 37, 41, 0.25);
  min-height: 150px;
}

.left_side_border {
  border-left: 10px solid hsl(181, 39%, 48%);
}

.right_side_border {
  border-right: 10px solid hsl(181, 39%, 48%);
}

.notice_left_border {
  border-left: 10px solid #e74c3c !important;
}

.ask_ai_left_border {
  border-left: 10px solid #1f6163 !important;
}

.question_left_border {
  border-left: 10px solid #6e82fe !important;
}

.comment_box_date_box {
  border-top: 1px dotted rgba(127, 127, 127, 0.3137254902);
  margin-top: 5px;
}

.comment_box_created, .comment_box_changed {
  flex: 20%;
  font-size: 10px;
  white-space: pre-line;
  color: hsl(0, 0%, 50%) !important;
  display: inline;
  font-size: 9px;
  margin-right: 8px;
  white-space: normal;
}

.comment_box_title, .comment_box_text, .comment_box_created, .comment_box_changed {
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  text-align: left;
}

.comment_box_changed {
  max-height: 45px;
}

.comment_box_created {
  max-height: 45px;
}

.comment_box_text {
  flex: 80% 1;
  font-size: 13px;
}

.comment_box_title {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
}
.comment_box_title:hover {
  cursor: pointer;
}
.comment_box_title > div[data-testid=flowbite-tooltip-target] {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.comment_box_title_process_step {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.comment_box_title_container {
  display: flex;
  flex: 70%;
  flex-direction: column;
  overflow: auto;
  max-height: 45px;
}

.comment_box_user {
  width: fit-content;
  position: relative;
}

.comment_box_reply_counter-hover, .comment_box_reply_counter {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-family: "Poppins Regular", sans-serif;
  height: 25px;
  justify-content: center;
  margin-left: 5px;
  margin-top: 5px;
  padding: 5px;
  text-align: center;
  width: 25px;
}

.comment_box_reply_counter-hover {
  background-color: hsl(180, 100%, 20%);
  color: sest-color(flat-colors, white-color);
}
.comment_box_reply_counter-hover + .comment_box_reply_counter_textbox {
  display: block !important;
}

.comment_box_reply_counter {
  background-color: hsl(181, 39%, 48%);
  color: #000;
}

.comment_box_reply_counter_textbox {
  background-color: hsl(0, 0%, 40%);
  border-radius: 5px;
  color: #fff;
  display: none;
  font-family: "Poppins Regular", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: 30px;
  text-align: center;
  text-decoration: none;
  top: 0;
  transition: 0.3s ease-in-out;
  z-index: 1;
}

.comment_user_profile-hover, .comment_user_profile {
  height: 35px;
  max-width: inherit;
  position: relative;
  width: auto;
}

.comment_user_profile-hover {
  background: hsl(0, 0%, 40%);
  border-radius: 5px;
  z-index: 2;
}

.comment_user_profile_textbox {
  background-color: hsl(0, 0%, 40%);
  border-radius: 5px;
  color: #fff;
  display: none;
  font-family: "Poppins Regular", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: 45px;
  text-align: center;
  text-decoration: none;
  top: 0;
  transition: 0.3s ease-in-out;
  z-index: 1;
  width: max-content;
}

.comment_box_user_image {
  display: block;
  background-position: top left !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 50%;
  height: 35px;
  margin-right: 5px;
  width: 35px;
  overflow: hidden;
}
.comment_box_user_image:hover {
  z-index: 5;
}
.comment_box_user_image:hover + .comment_user_profile_textbox {
  display: block !important;
}

.comment_user_profile_summary {
  background-color: hsl(0, 0%, 40%);
  border-radius: 5px;
  color: #fff;
  flex-direction: column;
  display: flex;
  font-family: "Poppins Regular", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 5px;
  position: absolute;
  right: 45px;
  text-align: left;
  text-decoration: none;
  top: 0;
  transition: 0.3s ease-in-out;
  z-index: 1;
  width: max-content;
  height: fit-content;
}

.comment_box_settings {
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  flex: 10% 1;
  height: 25px;
  width: 25px;
}

.comment_icons_list_block {
  margin-top: 10px;
}

.collaborate_icon {
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  position: absolute;
  width: 20px;
}

.control_icon_collaborate {
  flex: 0 0 75%;
  height: 25px;
  margin: 3px;
  padding: 2px;
  width: 25px;
}

.comment_icon_block {
  flex: auto;
  height: 25px;
  margin-right: 20px;
  margin-top: 5px;
  padding: 3px;
  width: 25px;
}

.comment_video_icon {
  margin-top: 3px;
}

.comment_video_icon, .comment_connect_icon, .comment_attachment_icon {
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  position: absolute;
  width: 20px;
}

.label_sign-up_header {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  clear: both;
  color: hsl(0, 0%, 20%);
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  padding-top: 10px;
  text-align: center;
}

.register_main_modal > div:first-child {
  height: inherit;
  max-width: 1050px;
  padding: 0;
}
.register_main_modal > div > div:first-child {
  background: transparent;
  top: 17vh;
  box-shadow: none;
}

.switch_role_body {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 hsl(0, 0%, 12%);
  box-sizing: border-box;
  height: 240px;
  margin-bottom: 5vh;
  margin-top: 25px;
}

.switch_role_select_role {
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 370px;
  justify-content: space-between;
  max-width: 400px;
}

.switch_role_roles_section div:last-child {
  margin-left: auto;
}

.card_role_switcher_background {
  align-self: flex-end;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 hsl(0, 0%, 12%);
  box-sizing: border-box;
  height: 245px;
  width: 400px;
}

.indicator_role_developer_select {
  border-radius: 50% !important;
  box-shadow: 0 0 20px 0 hsl(0, 0%, 12%) !important;
  outline: 5px solid hsl(180, 100%, 60%) !important;
}

.indicator_role_medical_expert_select {
  border-radius: 50% !important;
  box-shadow: 0 0 20px 0 hsl(0, 0%, 12%) !important;
  outline: 5px solid hsl(180, 100%, 60%) !important;
}

.indicator_role_switcher {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 hsl(0, 0%, 12%);
  height: 200px;
  margin: 0 auto;
  position: relative;
  top: -115px;
  width: 200px;
}

.indicator_role_switcher_transition {
  transition-duration: 0.1s;
}

.label_role_switcher {
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 32px;
  font-weight: 700;
  height: 43px;
  line-height: normal;
  top: -90px;
}

.label_role_switcher, .label_description_role_switcher {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.label_description_role_switcher {
  font-family: "Poppins Regular", sans-serif;
  font-size: 15px;
  line-height: 20px;
  top: -75px;
  width: 360px;
}

.main_pic_slider_block {
  background-color: #1e98d7;
}

.user_role_image_register {
  left: -14%;
  top: 38% !important;
}

.user_role_image_verfiy, .user_role_image_reset_success, .user_role_image_reset, .user_role_image_register_success, .user_role_image_register, .user_role_image_login {
  border-radius: 50%;
  box-shadow: 0 0 10px 0 hsl(0, 0%, 12%);
  height: 180px;
  position: absolute;
  top: 35%;
  width: 180px;
}

.user_role_image_login {
  left: -19%;
  top: 34%;
}

.user_role_image_register_success {
  left: -16%;
  top: 20%;
}

.user_role_image_reset {
  left: -14%;
}

.user_role_image_reset_success {
  left: 0;
  top: 25%;
}

.user_role_image_verfiy {
  left: 0%;
  top: 32%;
}

#label_login_header {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 32px;
  font-weight: 700;
  height: 43px;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
}

#label_text_link-sign-up {
  color: hsl(0, 0%, 12%);
}

#control_text_link-sign-up {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: center;
}

#label_text_link-sign-up {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: center;
}

#control_text_link-sign-up {
  color: hsl(181, 39%, 48%);
}

#control_text_link-sign-up:hover {
  cursor: pointer;
}

#password_login, #email_login {
  width: 450px;
}

#control_text_forgot-password {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(181, 39%, 48%);
  float: right;
  font-family: "Poppins Regular", sans-serif;
  font-size: 12px;
  padding-top: 5px;
  text-align: right;
}

#control_text_forgot-password:hover {
  cursor: pointer;
}

.reset-form, .login-form {
  margin-top: 25px;
}

.errorLabel {
  color: #e54646;
}

#label_verficate-user-account-call-to-action {
  font-size: 14px;
  padding: 15px 0 30px;
}

#label_verficate-user-account-call-to-action, #label_resend-verification-email {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  letter-spacing: 0.4px;
  text-align: center;
}

#label_resend-verification-email {
  padding: 15px;
}

#label_verfication_state-description {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 30px;
  text-align: center;
}

.verify_modal_content {
  margin: 0 auto;
  width: 560px;
}

.reset_passwor_modal_content {
  margin: 0 auto;
  width: 600px;
}

#reset_password, #repeat_reset_password {
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  height: 45px;
  margin-top: 0;
  padding: 2px 2px 2px 16px;
  text-align: left;
  width: 670px;
}

.container_recaptcha_reset {
  display: flex;
  align-items: center;
  justify-content: center;
}

#label_email-reset-password {
  box-sizing: border-box;
  font-family: "Poppins Regular", sans-serif;
  height: 19px;
  letter-spacing: 0.4px;
  line-height: normal;
}

#label_password_reset_header {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 32px;
  font-weight: 700;
  height: 43px;
  line-height: 40px;
  margin-bottom: 50px;
  text-align: center;
}

.reset_password_subtext {
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  text-align: center;
}

#label_reset_password_call-to-action {
  font-family: "Poppins Regular", sans-serif;
  height: 38px;
  letter-spacing: 0.4px;
}

#label_reset_password_call-to-action, #label_reset-password_description {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  line-height: normal;
  text-align: center;
}

#label_reset-password_description {
  font-family: "Poppins Regular", sans-serif;
  padding: 5px 0 10px;
}

#label_confirmation_state-description {
  letter-spacing: 0.4px;
}

#label_confirmation_state-description, #label_confirmation_description {
  background-color: hsla(0, 0%, 100%, 0);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  line-height: normal;
  margin: 0 auto;
  text-align: center;
}

#label_confirmation_description {
  padding: 20px;
}

.resetFormMargin {
  margin: 0px 80px;
}

.passwordCheck > li {
  align-items: center !important;
  display: inline-flex !important;
  font-family: "Poppins Regular", sans-serif !important;
  font-size: 12px !important;
  margin: 2px 4px !important;
  z-index: 10 !important;
}

.passwordCheck > li > span {
  opacity: 1 !important;
}

.passwordCheck > .invalid {
  color: #d90018 !important;
  opacity: 1 !important;
}

.invalid > svg > path {
  fill: #d90018 !important;
}

.valid > svg > path {
  fill: #19eef2 !important;
}

.passwordCheck > .valid {
  color: #19eef2 !important;
}

.checklist-icon {
  width: 12px !important;
}

.all_comments_list {
  height: auto;
  overflow: auto;
  padding: 7px;
}
.all_comments_list::-webkit-scrollbar {
  width: 2px;
}
.all_comments_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.all_comments_list::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.dashboard-scrollable {
  overflow: auto;
}
.dashboard-scrollable::-webkit-scrollbar {
  width: 2px;
}
.dashboard-scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}
.dashboard-scrollable::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.close_comment_settings {
  float: right;
  height: 10px;
  margin-top: 1px;
  width: 10px;
}

.finish_comment_block, .close_comment_block {
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 35px;
  position: absolute;
  width: 35px;
  z-index: 100;
}

.close_comment_box {
  height: 15px;
  left: 10px;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  width: 15px;
}

.comment_annotation_circle,
.comment_anno_indicator {
  background: #fff;
  z-index: 10;
}

.annotation-box {
  z-index: 8;
}

.comment_annotation_circle_after:after {
  margin: 0 0 5px 21px;
}

.comment_annotation_circle_before:before, .comment_annotation_circle_after:after {
  background-color: hsl(181, 39%, 48%);
  content: " ";
  display: inline-block;
  height: 2px;
  text-shadow: none;
  width: 100px;
}

.comment_annotation_circle_before:before {
  margin: 0 0 5.5px -120px;
}

.finish_comment_box {
  cursor: pointer;
  height: 17px;
  left: 9px;
  margin: 0 auto;
  position: absolute;
  top: 12px;
  width: 17px;
}

.comment_card {
  position: relative;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  user-select: none;
}

.comment_card.is-flipped {
  -webkit-transform: translateX(-100%) rotateY(-180deg);
  transform: translateX(-100%) rotateY(-180deg);
}

.comment_card_face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: contents;
  font-weight: 700;
  height: 95%;
  position: absolute;
  width: 95%;
}

.comment_time_card_face {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 11px;
  font-weight: 600;
  height: 51px;
  line-height: normal;
  padding: 3px 4px;
  position: absolute;
  text-align: left;
  width: 120px;
  z-index: 2;
}

.comment_card_icon_arrow {
  width: 30px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.comment_card_face--back {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.filter_category_checkbox {
  display: none;
}

.filter_category_checkbox_label {
  cursor: pointer;
  display: inline-block;
  width: 110px;
}

.select_cat_block {
  padding-left: 0.75rem;
}

.hideModal {
  display: none;
}

.showModal {
  display: inline-flex;
}

.icon_play {
  cursor: pointer;
  width: 17px;
  height: 19px;
}

.icon_comment_reply {
  cursor: pointer;
  width: 21px;
  height: 21px;
}

.all-category-icon .comment_reply_conter {
  top: 8px;
  right: -8px;
  line-height: 14px;
  font-family: "Poppins Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  padding: 0 5px;
  border-radius: 50px;
  background: #f2d019;
  color: #000;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment_card_sub_details {
  display: flex;
  gap: 5px;
}

.comment_box_username, .comment_box_time {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 15px;
  min-width: fit-content;
}

.icon_play_box {
  margin: 3px;
}

.icon_down {
  padding: 2px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  height: 21px;
  width: 21px;
}

.icon_up {
  cursor: pointer;
  height: 21px;
  width: 21px;
}

.category_focus, .category_normal {
  box-sizing: border-box;
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  line-height: normal;
  text-align: left;
}

.category_normal {
  font-weight: 400;
}

.category_focus {
  cursor: pointer;
  font-weight: 700;
}

.category_block_select {
  background-color: #fff;
  border: 2px solid hsl(0, 0%, 90%);
  border-bottom: 0;
  border-left: 0;
  border-radius: 5px;
  border-right: 0;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
  padding: 2px;
  position: absolute;
  top: 95px;
  width: 90%;
  z-index: 2;
}

#insert_CommentText:hover, #insert_CommentText:focus {
  border: 1px solid hsl(0, 0%, 40%) !important;
  box-shadow: none;
}

#insert_CommentText {
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  color: #000;
  font-family: "Poppins Regular", sans-serif;
  font-style: italic;
  font-weight: 400;
  padding: 2px;
  resize: none;
  text-align: left;
}
#insert_CommentHead:focus, #insert_CommentHead:hover, #insert_CommentHead {
  border: none;
  box-shadow: none;
}

#insert_CommentHead {
  width: 99%;
}
textarea:focus {
  border: none !important;
  box-shadow: none;
  outline: none;
}

.category_menu_item {
  color: hsl(0, 0%, 12%);
  cursor: pointer;
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-weight: 100;
  line-height: normal;
  text-align: left;
  width: 140px;
}

.category_checkbox_label {
  cursor: pointer;
  width: 110px;
}

.category_display {
  display: flex;
  height: 16px;
  padding-left: 0.75rem;
}

.playerOverlay {
  background: transparent;
  height: 100%;
  position: absolute;
  top: 0;
  width: 80%;
}

.flag_arrow {
  background-color: hsl(181, 39%, 48%);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  height: 25px;
  padding: 2px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px;
}

.FadeInAndOut {
  -webkit-animation: fadeinout 1s linear 2 forwards;
  -moz-animation: fadeinout 1s linear 2 forwards;
  animation: fadeinout 1s linear 2 forwards;
  background-color: rgba(75, 171, 172, 0);
  height: 200px;
  width: 200px;
}

@-webkit-keyframes fadeinout {
  0%, to {
    background-color: rgba(75, 171, 172, 0);
  }
  50% {
    background-color: hsl(50, 82%, 53%);
  }
}
@keyframes fadeinout {
  0%, to {
    background-color: rgba(75, 171, 172, 0);
  }
  50% {
    background-color: hsl(50, 82%, 53%);
  }
}
.FadeInAndOut_Border {
  -webkit-animation: fadeinout_border 1s linear 2 forwards;
  -moz-animation: fadeinout_border 1s linear 2 forwards;
  animation: fadeinout_border 1s linear 2 forwards;
}

@-webkit-keyframes fadeinout_border {
  0%, to {
    border-bottom: 2px solid hsl(180, 100%, 60%);
  }
  50% {
    border: 2px solid hsl(50, 82%, 53%);
  }
}
@keyframes fadeinout_border {
  0%, to {
    border-bottom: 2px solid hsl(50, 82%, 53%);
  }
  50% {
    border: 2px solid hsl(50, 82%, 53%);
  }
}
.intro-main-popup {
  padding: 30px 45px 5px;
}

.intro-popup > div:first-child {
  max-width: 850px;
}

.cursor-pointer {
  background: hsl(0, 0%, 90%);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 5px;
  width: 20px;
}

.cursor-pointer-active {
  background-color: hsl(181, 39%, 48%) !important;
}

.cursor-pointer-visited {
  background-color: hsl(180, 35%, 75%) !important;
}

.step-list {
  width: 92% !important;
}

.popup-heading-text {
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 0;
  padding: 15px 0;
  text-align: center;
}

.popup-sub-text {
  font-family: "Poppins Bold", "Poppins Regular", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  padding: 10px 0;
  text-align: center;
}

.popup-paragraph-subtext {
  font-family: "Poppins Bold", "Poppins Regular", sans-serif !important;
  font-weight: 600;
}

.popup-paragraph {
  color: hsl(0, 0%, 12%);
  font-family: "Poppins Regular", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 0;
  text-align: left;
}

.main-popup-content {
  height: 490px;
  padding-right: 15px;
}

.intro-left-side {
  width: 40%;
}

.intro-left-side > .popup-paragraph {
  font-size: 17px;
  line-height: 22px;
  padding-right: 23px;
  padding-top: 0;
}

.intro-right-side {
  width: 60%;
}

.next-prev-btn-main-block {
  width: 150px;
}

.intro-guide-block {
  margin-top: 30px;
}

.intro-popup-close {
  background: inherit;
  background-color: hsl(181, 39%, 48%);
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: #fff;
  font-family: "Poppins Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 150px;
}
.intro-popup-close:hover {
  background-color: hsl(180, 100%, 60%) !important;
}

.inro-popup-prev-block, .inro-popup-next-block {
  height: 30px;
  padding-bottom: 30px;
  width: 30px;
}

.inro-popup-next-block {
  float: right;
}
.inro-popup-next-block:hover {
  border-bottom: 2px solid hsl(50, 82%, 33%);
  cursor: pointer;
}
.inro-popup-prev-block {
  padding: 3px;
  position: absolute;
}
.inro-popup-prev-block:hover {
  border-bottom: 2px solid hsl(50, 82%, 33%);
  cursor: pointer;
}
.task-box-tooltip {
  background: #000;
  border-radius: 5px;
  color: #fff;
  display: none;
  font-family: "Poppins Regular", sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0 auto;
  padding: 2px 8px;
  width: -webkit-max-content;
  width: max-content;
}

figure:hover .task-box-tooltip {
  display: inline-block;
  left: 0;
  position: absolute;
  top: -22px;
  z-index: 1;
}

.person-task-bar div:last-child figure .task-box-tooltip {
  left: auto !important;
  right: -5px;
}

.expiration-box > div {
  width: 530px;
}

.timeCountDown {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border: 3px solid hsl(181, 39%, 48%);
  border-radius: 50%;
  line-height: 75px;
  color: hsl(181, 39%, 48%);
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
}

.text-tag, .user-tag-hover, .user-tag {
  font-family: inherit;
  font-size: inherit;
}

.user-tag {
  color: hsl(180, 100%, 30%);
  font-weight: 600;
}

.user-tag-hover {
  font-weight: 600;
}

.text-tag {
  color: #000;
  white-space: pre-line;
}

.process-tag {
  background-color: #90ee90;
}

.dropdown-menu {
  display: flex;
  height: 30px;
  align-items: baseline;
  margin-left: 11px;
  background-color: #aceeef;
  font-family: "Poppins Regular";
  font-weight: 500;
  size: 16px;
  line-height: 24px;
}

.dropdown-menu-icon {
  height: 30px;
  background-color: #4baaac;
}

.sidebar-icon-block {
  margin: 2px;
  font-family: "Poppins SemiBold", "Poppins Regular", sans-serif;
  font-weight: 600;
  font-size: 13px;
  height: 23px;
  padding: 3px;
  width: 23px;
  user-select: none;
}

.sidebar-icon-box {
  display: flex;
  width: auto;
  width: auto;
  margin-right: 5px;
  align-items: center;
}

.sidebar-categories-icon {
  width: 15px;
  padding: 0;
  margin: 1px 2px 0 2px;
  background-repeat: no-repeat;
  background-size: contain;
}

.comment_reply_box {
  position: absolute;
  right: 40px;
  top: 5px;
}

.search_icon_box {
  height: 20px;
  width: 20px;
  display: inline-block;
  background-size: contain;
  opacity: 0.5;
}
.search_icon_box_select {
  height: 20px;
  width: 20px;
  display: inline-block;
  background-size: contain;
  opacity: 1;
}

.loading_all {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: hsla(0, 0%, 0%, 0.52);
  z-index: 100;
  text-align: center;
  padding-top: 10%;
}

#quick_intro_header_text {
  box-sizing: #000;
  font-family: "Poppins Regular", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

#quick_intro_sub_heading {
  font-family: "Poppins Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.quick_intro_main_accordion {
  overflow-y: auto;
  height: 595px;
}
.quick_intro_main_accordion::-webkit-scrollbar {
  width: 6px;
}
.quick_intro_main_accordion::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.quick_intro_main_accordion::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

.drop-down-menu-icon-jp, .drop-down-menu-icon-de, .drop-down-menu-icon-eng {
  width: 24px;
  height: 24px;
  display: block;
}

.drop-down-tab-icon-roles-responsibilites-selected, .drop-down-tab-icon-tools-pharma-selected, .drop-down-tab-icon-rooms-equipment-selected, .drop-down-tab-icon-workflow-overview-selected, .drop-down-tab-icon-general-info-selected {
  width: 30px;
  height: 30px;
  transition: 100ms linear;
  background-size: contain;
}

.drop-down-tab-icon-roles-responsibilites, .drop-down-tab-icon-rooms-equipment, .drop-down-tab-icon-tools-pharma, .drop-down-tab-icon-workflow-overview, .drop-down-tab-icon-general-info {
  width: 24px;
  height: 20px;
  transition: 100ms linear;
  background-size: contain;
}

.drop-down-tab-dropdown-icon-selected, .drop-down-tab-dropdown-icon {
  width: 24px;
  height: 20px;
  background-size: contain;
}

.drop-down-main-block {
  margin-top: 15px;
  margin-right: 10px;
}
.drop-down-main-block-border-bottom {
  border-bottom: 1px solid #d9d9d9;
}
.drop-down-main-block-border-bottom:hover {
  border-radius: 5px;
  background: #f2f2f2;
}
.drop-down-tab-block {
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 30px;
  cursor: pointer;
}
.drop-down-tab-block:hover {
  border-radius: 5px;
  background: #f2f2f2;
}
.drop-down-tab-block-active {
  height: 40px;
}
.drop-down-tab-heading {
  position: absolute;
  left: 5%;
  color: #000;
  font-family: "Poppins Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  user-select: none;
}
.drop-down-left-content-block {
  overflow-y: auto;
  margin-right: 5px;
  width: 100%;
  padding-right: 10px;
}
.drop-down-left-content-block::-webkit-scrollbar {
  width: 6px;
}
.drop-down-left-content-block::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.drop-down-left-content-block::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}
.drop-down-content-block-border-buttom {
  border: 1px solid #d9d9d9;
}
.drop-down-content-block {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  user-select: none;
  padding-left: 10px;
  margin-top: 10px;
  height: 320px;
}
.drop-down-left-content-heading {
  color: #000;
  font-family: "Poppins Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.drop-down-left-content-text {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.drop-down-right-content-img-block {
  width: 59%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 5px;
}
.drop-down-resources-tabs button {
  background: #4baaac;
}
.drop-down-resources-text > p {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 5px 0px 0px 5px;
  border-left: 5px solid #4baaac;
  padding-left: 5px;
}
.drop-down-resources-text-hover > p {
  border-left: 5px solid #686a6f !important;
}
.drop-down-tabs-main-block {
  display: flex;
  justify-content: space-between;
  height: 300px;
}
.drop-down-tabs-block {
  width: 40%;
  background: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 3px 5px;
  margin-right: 5px;
}
.drop-down-tabs-scroll {
  overflow-y: auto;
  height: 290px;
}
.drop-down-tabs-scroll::-webkit-scrollbar {
  width: 6px;
}
.drop-down-tabs-scroll::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.drop-down-tabs-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}
.drop-down-button {
  margin: 5px;
  width: fit-content;
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 7px;
  background: #ccced8;
  cursor: pointer;
}
.drop-down-button:hover {
  background: #6b6f79 !important;
}
.drop-down-button.active {
  background: #4baaac;
}
.drop-down-tab-list {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
}
.drop-down-tabs-type-headline {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}
.drop-down-tabs-type-headline::first-letter {
  text-transform: uppercase;
}
.drop-down-menu-text {
  color: #212121;
  text-align: left;
  font-family: "Poppins Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.drop-down-menu-btn {
  display: flex;
  height: 30px;
  padding: 8px 0px;
  align-items: center;
  gap: 6px;
  border-radius: 2px;
  background: #aceeef;
  min-width: 60px;
}
.drop-down-menu-icon-bg {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0px 2.164px 0px 1.846px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px 0px 0px 2px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-color: #4baaac;
  background-position: center;
}
.drop-down-menu-icon-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
}
.drop-down-options {
  background: hsl(180.9, 100%, 97.59%);
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 10;
  max-height: 165px;
  overflow: auto;
}
.drop-down-options::-webkit-scrollbar {
  width: 6px;
}
.drop-down-options::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.drop-down-options::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}
.drop-down-option {
  list-style: none;
  padding: 5px;
  cursor: pointer;
  font-family: "Poppins Regular";
  display: flex;
  gap: 8px;
}
.drop-down-option:hover {
  background: rgba(172, 238, 239, 0.3098039216);
}
.drop-down-option-primary:hover {
  background: #ececec !important;
}
.drop-down-menu-with-icon {
  display: flex;
  position: relative;
  user-select: none;
  width: fit-content;
}
.drop-down-menu-selected {
  color: #4baaac;
}
.drop-down-tab-dropdown-icon,
.drop-down-tab-dropdown-icon-selected {
  width: 24px;
  height: 20px;
  background-size: contain;
}

.drop-down-tab-icon-general-info,
.drop-down-tab-icon-workflow-overview,
.drop-down-tab-icon-tools-pharma,
.drop-down-tab-icon-rooms-equipment,
.drop-down-tab-icon-roles-responsibilites {
  width: 24px;
  height: 20px;
  transition: 100ms linear;
  background-size: contain;
}

.drop-down-tab-icon-general-info-selected,
.drop-down-tab-icon-workflow-overview-selected,
.drop-down-tab-icon-rooms-equipment-selected,
.drop-down-tab-icon-tools-pharma-selected,
.drop-down-tab-icon-roles-responsibilites-selected {
  width: 30px;
  height: 30px;
  transition: 100ms linear;
  background-size: contain;
}

.quick_intro_main_accordion {
  overflow-y: auto;
  height: 595px;
}
.quick_intro_main_accordion::-webkit-scrollbar {
  width: 6px;
}
.quick_intro_main_accordion::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.quick_intro_main_accordion::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

.drop-down-content-block {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  user-select: none;
  padding-left: 10px;
  margin-top: 10px;
  height: 320px;
}

.drop-down-content-block-border-buttom {
  border: 1px solid #d9d9d9;
}

.drop-down-left-content-block {
  overflow-y: auto;
  margin-right: 5px;
  width: 100%;
  padding-right: 10px;
}
.drop-down-left-content-block::-webkit-scrollbar {
  width: 6px;
}
.drop-down-left-content-block::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.drop-down-left-content-block::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

.drop-down-left-content-heading {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.drop-down-left-content-text {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.quick-intro-popup > div:first-child {
  max-width: 1025px;
  padding: 11px;
  background: #fff;
  border-radius: 6px;
}

.drop-down-right-content-img-block {
  width: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 5px;
}

.drop-down-resources-tabs button {
  background: #4baaac;
}

.drop-down-resources-text > p {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 5px 0px 0px 5px;
  border-left: 5px solid #4baaac;
  padding-left: 5px;
}

.drop-down-resources-text-hover > p {
  border-left: 5px solid #686a6f !important;
}

.drop-down-tabs-main-block {
  display: flex;
  justify-content: space-between;
  height: 300px;
}

.drop-down-tabs-block {
  width: 30%;
  background: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 3px 5px;
  margin-right: 5px;
}

.drop-down-tabs-scroll {
  overflow-y: auto;
  height: 290px;
}
.drop-down-tabs-scroll::-webkit-scrollbar {
  width: 6px;
}
.drop-down-tabs-scroll::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.drop-down-tabs-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

.drop-down-button {
  margin: 5px;
  width: fit-content;
  color: #fff;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 7px;
  background: #ccced8;
  cursor: pointer;
}
.drop-down-button:hover {
  background: #6b6f79 !important;
}

.drop-down-button.active {
  background: #4baaac;
}

.drop-down-tab-list {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
}

.quick-intro-popup > div > div {
  max-height: 100vh !important;
  overflow-y: auto;
  box-shadow: unset;
}
.quick-intro-popup > div > div::-webkit-scrollbar {
  width: 6px;
}
.quick-intro-popup > div > div::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin: 10px 0;
}
.quick-intro-popup > div > div::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

.quick-intro-popup {
  overflow: hidden;
}

.quick_intro_popup_body {
  overflow: unset !important;
}

.quick_intro_popup_body > div {
  height: 680px;
}

.quick_intro_popup_body > div > div {
  height: 660px;
}

.drop-down-tabs-type-headline {
  color: #000;
  font-family: "Poppins Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.drop-down-tabs-type-headline::first-letter {
  text-transform: uppercase;
}

.drop-down-tab-content.drop-down-resources-text {
  margin: 10px 0;
}

button.drop-down-menu-btn {
  display: flex;
  height: 30px;
  padding: 8px 0px;
  align-items: center;
  gap: 6px;
  border-radius: 2px;
  background: #aceeef;
  min-width: 170px;
}

span.drop-down-menu-text {
  color: #212121;
  text-align: center;
  font-family: "Poppins Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.group-input-drop-down {
  display: flex;
  gap: 15px;
  align-items: flex-end;
}

input#ProcessName {
  height: 32px;
}

.drop-down-menu-icon-bg {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0px 2.164px 0px 1.846px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px 0px 0px 2px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-color: #4baaac;
  background-position: center;
}

.drop-down-menu-icon-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
}

nav.drop-down-options {
  background: hsl(180.9, 100%, 97.59%);
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 10;
  max-height: 165px;
  overflow: auto;
}
nav.drop-down-options::-webkit-scrollbar {
  width: 6px;
}
nav.drop-down-options::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
nav.drop-down-options::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #adb5bd;
}

li.drop-down-option {
  list-style: none;
  padding: 5px 0 0 6px;
  cursor: pointer;
  font-family: "Poppins Regular";
  display: flex;
  gap: 15px;
  transition: background-color 0.3s;
}
li.drop-down-option:hover {
  background: rgba(172, 238, 239, 0.3098039216);
}

li.drop-down-option-primary:hover {
  background: #ececec !important;
}

.drop-down-menu-with-icon {
  display: flex;
  position: relative;
  user-select: none;
  width: fit-content;
}

.drop-down-menu-icon-eng,
.drop-down-menu-icon-de,
.drop-down-menu-icon-jp {
  width: 24px;
  height: 24px;
  display: block;
}

span.dorp-down-menu-selected {
  color: #4baaac;
}

[type=text],
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea,
select {
  font-family: "Poppins Regular";
}

.mention-input__suggestions {
  top: 30px !important;
  height: 120px;
  overflow-y: auto;
}
.mention-input__suggestions::-webkit-scrollbar {
  width: 2px;
}
.mention-input__suggestions::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 2px;
}
.mention-input__suggestions::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 70%);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.mention-input__suggestions__item {
  padding: 5px !important;
  font-family: "Poppins Regular";
}

.player_slider_blocks {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
}

.sidebar-search-box {
  height: 35px !important;
}

.toggle-button {
  width: 40px;
  height: 25px;
  border-radius: 100px;
  background-color: rgb(173, 181, 189);
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.toggle-button.active {
  background-color: rgb(75, 170, 172);
}

.handler {
  width: 21px;
  height: 21px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: 0px 2px 2px 0px rgba(33, 37, 41, 0.08);
  transition: transform 0.3s ease-in-out;
  transform: translateX(2px);
}

.toggle-button.active .handler {
  transform: translateX(17px);
  /* Adjust based on the active state */
}

.notification-within-app-toggle-button {
  display: flex;
}

.notification-label {
  font-family: "Poppins Regular";
  width: 300px;
  height: 50px;
  display: block;
}

.notification-within-app-toggle-button span {
  width: 300px;
  height: 50px;
  font-family: "Poppins Regular";
}

/* VideoPlayer.css */
.video-player {
  position: relative;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player-uploaded {
  position: relative;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.comment-full-slider-block {
  width: 70vw;
  height: 70vh;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
  cursor: pointer;
  z-index: 10;
  user-select: none;
}

.progress-bar {
  position: relative;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 8.5px;
  background: gray;
  z-index: 5;
  cursor: pointer;
  border-radius: 5px;
}

.progress {
  height: 100%;
  background: white;
  transition: width 0.2s;
  border-radius: 5px;
}

.progress-indicator {
  position: absolute;
  bottom: -4px;
  width: 17px;
  height: 17px;
  background: white;
  border-radius: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  transition: left 0.2s;
}

.custom-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
}

.custom-video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  pointer-events: auto;
}

.play-button-icon-big {
  width: 100px;
  height: 100px;
  display: block;
}

.play-button-icon-small {
  width: 50px;
  height: 50px;
  display: block;
}

.small-toggle-button {
  width: 20px;
  height: 14px;
  border-radius: 100px;
  background-color: rgb(173, 181, 189);
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.small-handler {
  width: 12px;
  height: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: 0px 2px 2px 0px rgba(33, 37, 41, 0.08);
  transition: transform 0.3s ease-in-out;
  transform: translateX(1px);
}

.small-toggle-button.active {
  background-color: rgb(75, 170, 172);
}

.small-toggle-button.active .small-handler {
  transform: translateX(7px);
  /* Adjust based on the active state */
}

.filter-box-list {
  overflow-x: scroll;
  max-height: 400px;
}

.filter-box-list-item:hover {
  display: flex;
  background: #319795;
  cursor: pointer;
}

.signin-btn {
  background: #319795 !important;
}

@media (orientation: landscape) and (max-width: 932px) {
  .video_player_block {
    max-width: 73% !important;
    margin: unset !important;
  }
}
